<template>
  <div>
    <div class="sticky pt-4 px-4 rounded-xl top-0 z-10 bg-zinc-800 shadow-xl">
      <slot name="toolbar" />

      <!-- title -->
      <div class="mt-4 flex items-center gap-x-2">
        <div class="flex flex-col items-center justify-center gap-2 w-16">
          <div class="p-2 rounded-full shadow-lg" :class="genClass('iconBg', props.card.color)">
            <component :is="iconMap[props.card.type]" class="size-8" />
          </div>
          <small class="text-center w-full truncate text-white">{{ $t(props.card.type) }}</small>
        </div>
        <div>
          <small class="font-mono text-zinc-300">{{ props.card.id }}</small>
          <h3 class="text-2xl font-bold text-white">{{ translate(props.card, 'title') }}</h3>
          <p class="text-zinc-300">#{{ translate(props.card, 'productName') }}</p>
        </div>
      </div>

      <!-- info -->
      <div class="mt-4 flex items-center gap-2 flex-wrap">
        <div v-for="(info, infoid) in infos" :key="`info-${infoid}`" class="flex items-center">
          <span class="relative z-1 px-1 rounded whitespace-nowrap" :class="genClass('info', props.card.color)">
            {{ $t(info.name) }}
          </span>
          <span
            class="bg-black/30 rounded-r pl-2 pr-1 -ml-1 whitespace-nowrap text-white text-center font-mono md:min-w-[2rem]">
            {{ props.card[info.field] }}
          </span>
        </div>
      </div>

      <!-- quick link -->
      <div class="mt-6 flex items-center justify-center gap-2 overflow-auto">
        <button v-for="(link, linkid) in links" :key="`qlink-${linkid}`" class="
        group rounded-full flex items-center default-transition
        bg-zinc-700 text-zinc-100 hover:bg-cyan-700 hover:text-cyan-100" @click="scrollTo(link.field)">
          <div class="btn btn-sm default-transition  bg-zinc-600 group-hover:bg-cyan-600">
            <component :is="link.icon" />
          </div>
          <p class="hidden md:block px-0 md:pl-1 md:pr-2 whitespace-nowrap">
            {{ $t(`cardDetail.${link.i18n}`) }}
          </p>
        </button>
      </div>

      <hr class="my-2 -mx-4 border-zinc-700" />
    </div>

    <div ref="content" class="flex flex-col gap-4 p-4">
      <section :id="link.field" class="flex flex-col gap-4" v-for="(link, linkid) in links" :key="`link-${linkid}`">
        <!-- title -->
        <h4 class="text-cyan-500 flex items-center gap-1">
          <component :is="link.icon" class="size-6" />
          <span class="text-lg leading-none font-bold"> {{ $t(`cardDetail.${link.i18n}`) }} </span>
          <StarIcon v-if="link.isMember" class="size-6 text-amber-500" />
        </h4>

        <!-- content -->
        <CardDetailEffect v-if="link.field === 'info'" :effects="effects" />
        <CardDetailRelation v-else-if="link.field === 'relation'" :ids="props.card.relation" />
        <CardDetailPrice v-else-if="link.field === 'price'" :price="props.card.price" :highVer="props.card.highVer"
          :lowVer="props.card.lowVer" />
        <CardDetailQA v-else-if="link.field === 'qa'" :id="props.card.id" />
        <UIComment v-else-if="link.field === 'chat'" target="card" :code="props.card.id" />
        <template v-else-if="link.field === 'trend'">
          <CardDetailTrend v-if="user.role === 'member'" :id="card.id" />
          <UIEmpty v-else />
        </template>

        <hr v-if="linkid !== links.length - 1" class="my-2 -mx-4 border-zinc-700" />
      </section>
    </div>
  </div>
</template>


<script setup>
import {
  BoltIcon,
  PhotoIcon,
  UserIcon,
  InformationCircleIcon,
  ArrowPathRoundedSquareIcon,
  QuestionMarkCircleIcon,
  ChatBubbleOvalLeftEllipsisIcon,
  CurrencyDollarIcon,
  ArrowTrendingUpIcon,
} from '@heroicons/vue/24/outline'
import { StarIcon } from '@heroicons/vue/24/solid'

const preferenceStore = usePreferenceStore()
const { translate, autoTranslateZh } = preferenceStore
const { setting } = storeToRefs(preferenceStore)
const { user } = storeToRefs(useGlobalStore())

const props = defineProps({
  card: {
    type: Object,
    default: {},
  },
})

const effects = computed(() => {
  const t = translate(props.card, ['effect', 'author'])

  if (t[0] === props.card.effect && setting.value.translationLanguage === 'zh' && setting.value.translation) {
    return [autoTranslateZh(props.card.effect, '<span class="font-bold !text-indigo-500">{{token}}</span>'), '']
  }

  else return t
})

const genClass = (type, color) => {
  const colors = ['yellow', 'red', 'blue', 'green', 'purple']
  const types = {
    iconBg: (c) => `shadow-${c}-200/50 bg-${c}-200 text-${c}-700`,
    info: (c) => `bg-${c}-100 text-${c}-700 dark:bg-${c}-700/50 dark:text-${c}-100`,
  }

  return types[type](colors.includes(color) ? color : 'zinc')
}

const iconMap = ref({
  イベント: BoltIcon,
  クライマックス: PhotoIcon,
  キャラ: UserIcon,
})

const infos = ref([
  { name: '等級', field: 'level' },
  { name: '費用', field: 'cost' },
  { name: '魂傷', field: 'soul' },
  { name: '攻擊', field: 'attack' },
  { name: '稀有度', field: 'rare' },
])

const links = ref([
  { name: '資訊', field: 'info', i18n: 'linkInfo', icon: InformationCircleIcon },
  { name: '連動', field: 'relation', i18n: 'linkRelation', icon: ArrowPathRoundedSquareIcon },
  { name: '價格', field: 'price', i18n: 'linkPrice', icon: CurrencyDollarIcon },
  { name: 'QA', field: 'qa', i18n: 'linkQA', icon: QuestionMarkCircleIcon },
  { name: '留言', field: 'chat', i18n: 'linkChat', icon: ChatBubbleOvalLeftEllipsisIcon },
  { name: '趨勢', field: 'trend', i18n: 'linkTrend', icon: ArrowTrendingUpIcon, isMember: true },
])

const scrollTo = (id) => {
  // nowLink.value = id
  document.querySelector(`#${id}`).scrollIntoView({
    behavior: 'smooth',
    block: 'end',
  })
}

</script>

<style lang="sass" scoped>
.init-tailwind-style
  @apply bg-yellow-100  bg-red-100  bg-green-100  bg-blue-100 bg-zinc-100
  @apply bg-yellow-200  bg-red-200  bg-green-200  bg-blue-200 bg-zinc-200
  @apply dark:bg-yellow-700/50  dark:bg-red-700/50  dark:bg-green-700/50  dark:bg-blue-700/50 dark:bg-purple-700/50 dark:bg-zinc-700/50
  @apply text-yellow-700  text-red-700  text-green-700  text-blue-700 text-zinc-700
  @apply shadow-yellow-200/50 shadow-red-200/50 shadow-green-200/50 shadow-blue-200/50 shadow-zinc-200/50
  @apply dark:text-yellow-100  dark:text-red-100  dark:text-green-100  dark:text-blue-100 dark:text-purple-100 dark:text-zinc-100
</style>