<template>
  <div class="flex flex-col gap-4 my-4">
    <div class="bg-amber-500/50 p-2 rounded-2xl flex items-center gap-2">
      <ExclamationTriangleIcon class="size-8 text-amber-500" />
      <p class="text-white">
        請注意！牌組代碼只能支援於貓罐子使用，並不能當作官方比賽使用。 <br />
        如需上傳代碼，請使用
        <a class="link" @click="switchToDeckLog">上傳至 Decklog</a> 功能。
      </p>
    </div>
    <div class="grid place-content-center">
      <img :src="qrcode" alt="QR Code" class="rounded-2xl max-w-[14rem]" />
    </div>

    <div v-for="info in shareInfos" :key="info.name">
      <h3 class="text-zinc-100 font-bold mb-2">{{ info.name }}</h3>

      <div class="p-2 ring-1 ring-zinc-200/30 rounded-lg text-zinc-300 font-mono flex items-start gap-2">
        <span class="my-auto">{{ info.content }}</span>
        <button
          class="ml-auto p-2 rounded-lg"
          :class="{
            'bg-zinc-800': !(copied && text === info.content),
            'bg-green-600': copied && text === info.content
          }"
          @click="copy(info.content)"
          :disabled="!isSupported"
        >
          <CheckIcon class="size-5" v-if="copied && text === info.content" />
          <ClipboardDocumentListIcon v-else class="size-5" />
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useQRCode } from '@vueuse/integrations/useQRCode'
import { ClipboardDocumentListIcon, CheckIcon, ExclamationTriangleIcon } from '@heroicons/vue/24/outline'
import { useClipboard } from '@vueuse/core'

const { t } = useNuxtApp().$i18n
const { text, copy, copied, isSupported } = useClipboard()

const { showPane } = storeToRefs(usePreferenceStore())
const { nowDeck } = storeToRefs(useDeckStore())

const props = defineProps({
  url: {
    type: String,
    required: ''
  },
  text: {
    type: String,
    required: ''
  }
})

const shareInfos = computed(() => [
  { name: t('paneShare.shareUrl'), content: props.url },
  { name: t('paneShare.shareText'), content: props.text }
])

const router = useRouter()

const switchToDeckLog = () => {
  showPane.value = false
  router.push({ query: { open: 'deckLogUpload', code: nowDeck.value.code } })
  setTimeout(() => (showPane.value = 'deckLogUpload'), 100)
}

const qrcode = useQRCode(props.url, {
  width: 300,
  margin: 2,
  color: {
    dark: '#18181b',
    light: '#f4f4f5'
  }
})
</script>

<style lang="sass" scoped>
.link
  @apply cursor-pointer text-blue-400 hover:text-blue-500 inline-flex items-center gap-1
</style>
