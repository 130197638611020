<template>
  <div class="h-full flex flex-col gap-2 pt-4 text-white divide-y divide-white/10 min-h-[80vh] md:min-h-[40rem]">
    <div>
      <h3 class="topic">製作卡片</h3>

      <!-- stepper -->
      <div class="flex justify-center items-center gap-4">
        <div
          class="p-2 rounded-xl flex items-center gap-1"
          :class="{
            'text-cyan-500': stepper === 1,
            'text-zinc-500': stepper !== 1
          }"
        >
          <RectangleStackIcon class="size-6 flex-none" />
          <span>選擇種類</span>
        </div>
        <div
          class="p-2 rounded-xl flex items-center gap-1"
          :class="{
            'text-cyan-500': stepper === 2,
            'text-zinc-500': stepper !== 2
          }"
        >
          <ArrowUpTrayIcon class="size-6 flex-none" />
          <span>上傳圖片</span>
        </div>
        <div
          class="p-2 rounded-xl flex items-center gap-1"
          :class="{
            'text-cyan-500': stepper === 3,
            'text-zinc-500': stepper !== 3
          }"
        >
          <AdjustmentsHorizontalIcon class="size-6 flex-none" />
          <span>編輯內容</span>
        </div>
      </div>

      <div class="relative h-full py-2 px-0 md:p-4">
        <component :is="stepperTransition">
          <div v-if="stepper === 1" class="h-full">
            <div class="w-full md:w-[80%] mx-auto grid grid-cols-3 place-content-center gap-4">
              <div class="flex flex-col gap-4 items-center" v-for="item in images" :key="item.field">
                <button class="item default-transition" :disabled="isExport" @click="chooseItem(item)">
                  <PhotoIcon class="icon size-6" />
                  <span class="text-sm font-mono">{{ item.name }}</span>
                </button>
                <img :src="item.img || '/images/loading.png'" alt="" class="img-preview" />
              </div>
            </div>
            <div class="mt-8 flex justify-center">
              <button class="item md:!w-[20rem] justify-center default-transition" :disabled="isHaveEmpty" @click="isExport = true">
                <CheckIcon class="icon size-6" />
                <span> 製作完成 </span>
              </button>
            </div>
          </div>
          <div v-else-if="stepper === 2" class="h-[650px]">
            <CoverCropper @cancel="goToStep(1)" @change="setImage" />
          </div>
          <div v-else-if="stepper === 3" class="h-[650px]">
            <CoverEditor :input="cropImage" @cancel="goToStep(2)" @change="saveImage" />
          </div>
        </component>
      </div>
    </div>

    <div class="py-4 flex flex-col gap-4" v-if="isExport">
      <h3 class="topic mb-2">選擇紙張大小</h3>
      <div class="grid grid-cols-2 gap-2">
        <button
          v-for="item in pdfSizes"
          :key="item.name"
          class="item default-transition"
          :disabled="loading"
          :class="{ active: nowPdfSize.field === item.field }"
          @click="nowPdfSize = item"
        >
          <component :is="item.icon" class="icon size-6" />
          <span> {{ item.name }} </span>
        </button>
      </div>
      <div class="flex justify-center">
        <button class="item md:!w-[20rem] justify-center default-transition" @click="doExport" :disabled="loading">
          <CheckIcon class="icon size-6" />
          <span> 設定完成 </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { RectangleStackIcon, AdjustmentsHorizontalIcon, ArrowUpTrayIcon, PhotoIcon, CheckIcon, DocumentIcon } from '@heroicons/vue/24/outline'

const { customizeExportPdf } = useExportPdf()
const { downloadObject } = useDownload()
const stepper = ref(1)

// transition
const direction = ref('right')

const transitions = ref({
  TransitionRight: resolveComponent('TransitionRight'),
  TransitionLeft: resolveComponent('TransitionLeft')
})

const stepperTransition = computed(() => (direction.value === 'right' ? transitions.value.TransitionRight : transitions.value.TransitionLeft))

// stepper
const goToStep = step => {
  if (step > stepper.value) direction.value = 'right'
  if (step < stepper.value) direction.value = 'left'

  setTimeout(() => {
    stepper.value = step
  }, 100)
}

// step 1
const images = ref([
  { field: '1st', name: '先攻正面', img: '/images/loading.png' },
  { field: '2nd', name: '後攻正面', img: '/images/loading.png' },
  { field: 'back', name: '卡片背面', img: '/images/loading.png' }
])

const selectField = ref('')
const isHaveEmpty = computed(() => {
  return images.value.map(e => e.img).includes('')
})

const chooseItem = item => {
  selectField.value = item.field
  goToStep(2)
}

// step 2
const cropImage = ref('')
const setImage = image => {
  cropImage.value = image
  goToStep(3)
}

// step 3
const saveImage = image => {
  images.value.find(i => i.field === selectField.value).img = image
  goToStep(1)
}

// export setting
const isExport = ref(false)
const pdfSizes = [
  { name: 'A4', field: 'A4', icon: DocumentIcon },
  { name: 'A4 (7-11 ibon)', field: 'ibon', icon: DocumentIcon }
]
const nowPdfSize = ref(pdfSizes[0])

// export
const loading = ref(false)

const doExport = async () => {
  loading.value = true
  let cards = [...images.value.map(e => e.img), images.value[images.value.length - 1].img]

  let blob = await customizeExportPdf('先後攻', cards, nowPdfSize.value.field === 'A4')
  const url = URL.createObjectURL(blob)
  downloadObject(url, `先後攻.pdf`)
  loading.value = false
}
</script>

<style lang="sass" scoped>

.topic
  @apply text-lg font-bold mr-auto text-zinc-200
  .subtitle
    @apply block font-normal text-zinc-400 text-xs

.item
  @apply w-full flex items-center gap-2 p-2 rounded-xl ring-2 ring-zinc-800
  &:not(.active)
    @apply bg-zinc-900/50 text-zinc-400 hover:bg-zinc-800 focus-within:bg-zinc-800 active:bg-zinc-800 disabled:text-zinc-800
  &.active
    @apply bg-gradient-to-r from-blue-500 to-cyan-500 shadow-sky-500/50 text-white
  .icon
    @apply flex-none stroke-2

.img-preview
  @apply bg-zinc-700 rounded-card flex-none shadow-lg aspect-card min-w-0 w-full select-none
</style>
