<template>
  <UIPopover :name="props.name" :className="props.class">
    <button class="flex-none p-1 rounded-full default-transition hover:bg-zinc-800/50" :class="props.class"
      @click.stop="emits('action')">
      <slot name="icon" />
    </button>

    <slot />
  </UIPopover>
</template>


<script setup>
const emits = defineEmits(['action'])
const props = defineProps({
  field: {
    type: String,
    default: 'Button',
  },
  name: {
    type: String,
    default: '',
  },
  class: {
    type: String,
    default: 'text-white',
  }
})

const isShow = ref(false)

</script>