<template>
  <section>
    <template v-if="ids.length > 0">
      <div class="grid grid-cols-3 md:grid-cols-5 place-content-center">
        <button
          v-for="(r, rid) in relations"
          :key="`r-${rid}`"
          class="rounded-xl p-2 min-w-0 flex flex-col items-center hover:bg-zinc-500/30"
          @click="openCard(r)"
        >
          <CardCover :src="r.scraper" :rare="r.rare" :isEffect="true" />
          <small class="mt-2 text-xs font-mono text-zinc-400">{{ r.id }}</small>
          <h3 class="w-full text-sm leading-none truncate text-white">{{ translate(r, 'title') }}</h3>
        </button>
      </div>
    </template>

    <template v-else>
      <div class="h-[10rem] grid place-content-center bg-black/20 text-white rounded-2xl">
        <div class="flex items-center">
          <HandRaisedIcon class="size-8" />
          <span>{{ $t('empty') }}</span>
        </div>
      </div>
    </template>
  </section>
</template>

<script setup>
import { HandRaisedIcon } from '@heroicons/vue/24/outline'

const props = defineProps({
  ids: {
    type: Array,
    default: []
  }
})

const { translate } = usePreferenceStore()
const { cardCollections, nowCard } = storeToRefs(useCardStore())

const relations = computed(() => cardCollections.value.filter(e => props.ids.includes(e.id)))

const openCard = card => {
  const data = JSON.parse(JSON.stringify(card))
  // console.log(data, 'relation')
  nowCard.value = data
  document.querySelector('#card-pane').scrollTo(0, 0)
}
</script>
