<template>
  <div class="h-full flex flex-col items-center gap-4">
    <div class="flex items-center gap-2 justify-between">
      <button class="p-2 rounded-xl bg-zinc-800 flex items-center gap-1" @click="emits('cancel')">
        <XMarkIcon class="size-4" />
        <span class="text-sm">取消</span>
      </button>
      <button class="p-2 rounded-xl bg-zinc-800 flex items-center gap-1" @click="getImage">
        <CheckIcon class="size-4" />
        <span class="text-sm">完成</span>
      </button>
    </div>

    <div>左方選單可以切換不同編輯功能</div>
    <div id="editor_container" class="w-full h-full" />
  </div>
</template>

<script setup>
import { XMarkIcon, CheckIcon } from '@heroicons/vue/24/outline'
const emits = defineEmits(['change', 'cancel'])
const { input } = defineProps({
  input: {
    type: String,
    default: '/images/loading.png'
  }
})

const filerobotImageEditor = ref()

const preview = ref()

const getImage = () => {
  const { imageData } = filerobotImageEditor.value.getCurrentImgData()
  emits('change', imageData.imageBase64)
}

const initImageEditor = image => {
  const { TABS, TOOLS } = window.FilerobotImageEditor

  const config = {
    source: image,
    theme: {
      palette: {
        'txt-primary': '#e4e4e7', // text color
        'icons-primary': '#e4e4e7', // icons color
        'bg-secondary': '#121212', // background color
        'bg-stateless': '#121212', // icon bg color
        'bg-hover': '#18181b',
        'bg-primary-active': '#3f3f46',
        'btn-primary-text': 'white', // button text color

        'accent-primary-active': '#ffffff',
        'icons-secondary': '#d4d4d8',

        'borders-secondary': '#ffffff20' // border color
      },
      typography: {
        fontFamily: "Roboto, 'Noto Sans TC', 'sans-serif'"
      }
    },
    tabsIds: [TABS.FINETUNE, TABS.FILTERS, TABS.ANNOTATE, TABS.WATERMARK],

    Text: { text: '文字' },
    Image: {
      gallery: [
        { originalUrl: '/images/crop/first0.png', previewUrl: '/images/crop/first0.png' },
        { originalUrl: '/images/crop/last0.png', previewUrl: '/images/crop/last0.png' },
        { originalUrl: '/images/crop/first1.png', previewUrl: '/images/crop/first1.png' },
        { originalUrl: '/images/crop/last1.png', previewUrl: '/images/crop/last1.png' },
        { originalUrl: '/images/crop/first2.png', previewUrl: '/images/crop/first2.png' },
        { originalUrl: '/images/crop/last2.png', previewUrl: '/images/crop/last2.png' },
        { originalUrl: '/images/crop/first3.png', previewUrl: '/images/crop/first3.png' },
        { originalUrl: '/images/crop/last3.png', previewUrl: '/images/crop/last3.png' },
        { originalUrl: '/images/crop/first4.png', previewUrl: '/images/crop/first4.png' },
        { originalUrl: '/images/crop/last4.png', previewUrl: '/images/crop/last4.png' }
      ]
    },
    Rotate: { angle: 90, componentType: 'slider' },
    translations: {
      name: '名稱',
      save: '保存',
      saveAs: '另存為',
      back: '返回',
      loading: '加載中...',
      resetOperations: '重置/刪除所有操作',
      changesLoseWarningHint: '如果您按下“重置”按鈕，您的更改將丟失。您要繼續嗎？',
      discardChangesWarningHint: '如果您關閉模式對話框，您最後的更改將不會被保存。',
      cancel: '取消',
      apply: '應用',
      warning: '警告',
      confirm: '確認',
      discardChanges: '放棄更改',
      undoTitle: '撤消上一次操作',
      redoTitle: '重做上一次操作',
      showImageTitle: '顯示原始圖像',
      zoomInTitle: '放大',
      zoomOutTitle: '縮小',
      toggleZoomMenuTitle: '切換縮放菜單',
      adjustTab: '調整',
      finetuneTab: '微調',
      filtersTab: '濾鏡',
      watermarkTab: '水印',
      annotateTabLabel: '標註',
      resize: '調整大小',
      resizeTab: '調整大小',
      imageName: '圖像名稱',
      invalidImageError: '提供的圖像無效。',
      uploadImageError: '上傳圖像時出錯。',
      areNotImages: '不是圖像',
      isNotImage: '不是圖像',
      toBeUploaded: '要上傳',
      cropTool: '裁剪',
      original: '原始',
      custom: '自定義',
      square: '正方形',
      landscape: '橫向',
      portrait: '豎向',
      ellipse: '橢圓',
      classicTv: '經典電視',
      cinemascope: '電影寬幅',
      arrowTool: '箭頭',
      blurTool: '模糊',
      brightnessTool: '亮度',
      contrastTool: '對比度',
      ellipseTool: '橢圓',
      unFlipX: '取消水平翻轉',
      flipX: '水平翻轉',
      unFlipY: '取消垂直翻轉',
      flipY: '垂直翻轉',
      hsvTool: 'HSV',
      hue: '色調',
      brightness: '亮度',
      saturation: '飽和度',
      value: '值',
      imageTool: '圖像',
      importing: '正在導入...',
      addImage: '+ 添加圖像',
      uploadImage: '上傳圖像',
      fromGallery: '從畫廊',
      lineTool: '直線',
      penTool: '筆',
      polygonTool: '多邊形',
      sides: '邊數',
      rectangleTool: '矩形',
      cornerRadius: '圓角半徑',
      resizeWidthTitle: '寬度（像素）',
      resizeHeightTitle: '高度（像素）',
      toggleRatioLockTitle: '切換比例鎖定',
      resetSize: '重置為原始圖像尺寸',
      rotateTool: '旋轉',
      textTool: '文本',
      textSpacings: '文本間距',
      textAlignment: '文本對齊',
      fontFamily: '字體',
      size: '大小',
      letterSpacing: '字母間距',
      lineHeight: '行高',
      warmthTool: '溫度',
      addWatermark: '+ 添加水印',
      addTextWatermark: '+ 添加文本水印',
      addWatermarkTitle: '選擇水印類型',
      uploadWatermark: '上傳水印',
      addWatermarkAsText: '添加為文本',
      padding: '內邊距',
      paddings: '內邊距',
      shadow: '陰影',
      horizontal: '水平',
      vertical: '垂直',
      blur: '模糊',
      opacity: '不透明度',
      transparency: '透明度',
      position: '位置',
      stroke: '描邊',
      saveAsModalTitle: '另存為',
      extension: '擴展名',
      format: '格式',
      nameIsRequired: '名稱是必需的。',
      quality: '質量',
      imageDimensionsHoverTitle: '保存的圖像尺寸（寬 x 高）',
      cropSizeLowerThanResizedWarning: '注意，所選的裁剪區域小於應用的調整大小，這可能導致質量降低',
      actualSize: '實際大小（100%）',
      fitSize: '適合大小',
      addImageTitle: '選擇要添加的圖像...',
      mutualizedFailedToLoadImg: '無法加載圖像。',
      tabsMenu: '菜單',
      download: '下載',
      width: '寬度',
      height: '高度',
      plus: '+',
      cropItemNoEffect: '此裁剪項目沒有可用的預覽'
    },

    Watermark: {
      gallery: [
        '/images/crop/first0.png',
        '/images/crop/last0.png',
        '/images/crop/first1.png',
        '/images/crop/last1.png',
        '/images/crop/first2.png',
        '/images/crop/last2.png',
        '/images/crop/first3.png',
        '/images/crop/last3.png',
        '/images/crop/first4.png',
        '/images/crop/last4.png'
      ]
    },
    defaultTabId: TABS.WATERMARK,
    defaultToolId: TOOLS.IMAGE,
    defaultSavedImageName: 'image',
    defaultSavedImageType: 'png',
    defaultSavedImageQuality: 1,
    previewPixelRatio: 4,

    removeSaveButton: true
  }

  // Assuming we have a div with id="editor_container"
  filerobotImageEditor.value = new window.FilerobotImageEditor(document.querySelector('#editor_container'), config)

  filerobotImageEditor.value.render({
    onClose: closingReason => {
      console.log('Closing reason', closingReason)
      filerobotImageEditor.value.terminate()
    }
  })
}

onMounted(() => {
  initImageEditor(input)
})
</script>

<style scoped>
:deep(.FIE_tabs) {
  box-shadow: none;
}

:deep(.FIE_watermark-tool-wrapper) {
  width: 100%;
}

:deep(.FIE_watermark-gallery-wrapper) {
  width: 100% !important;
}

:deep(.FIE_topbar-close-button) {
  display: none;
}

:deep(.FIE_watermark-selected-item) {
  height: 70px;
  min-width: 50px;
  display: flex;
  justify-content: center;
}

:deep(.FIE_text-size-option) {
  background-color: #121212;
}

:deep(.FIE_carousel-prev-button) {
  width: 80px;
  background: linear-gradient(90deg, #121212 1.56%, #121212e3 52.4%, #12121287 76.04%, transparent 100%);
}

:deep(.FIE_carousel-next-button) {
  width: 80px;
  background: linear-gradient(270deg, #121212 1.56%, #121212e3 52.4%, #12121287 76.04%, transparent 100%);
}
</style>
