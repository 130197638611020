<template>
  <div class="relative z-10 flex items-center gap-4 ">
    <div class="header-content scroll-hidden default-transition min-w-0">
      <h2 class="truncate font-bold">{{ nowTopic?.title || $t('headerSocial.social') }}</h2>
    </div>
    <div class="ml-auto flex-none action flex items-center gap-2 h-min">
      <ItemHeaderButton :name="$t('navbar.share')" field="share" @action="doShare">
        <template #icon>
          <ShareIcon class="size-6 stroke-2" />
        </template>
      </ItemHeaderButton>
    </div>
  </div>
</template>
  
<script setup>
import { ShareIcon, AdjustmentsHorizontalIcon, PencilSquareIcon, TrashIcon, DocumentArrowDownIcon, ChatBubbleLeftRightIcon } from '@heroicons/vue/24/outline'
import { onClickOutside } from '@vueuse/core'

const { nowTopic } = storeToRefs(useDeckStore())
const { shareText, shareUrl } = storeToRefs(useGlobalStore())
const { showPane } = storeToRefs(usePreferenceStore())

const showMenu = ref(false)
const { share, isSupported } = useShare()
const { matchSize } = useRwd()
const { t } = useNuxtApp().$i18n

const position = computed(() => {
  return matchSize(['xs', 'sm', 'md']) ? { right: 0, top: 0 } : { left: 0, top: 0 }
})

const doShare = () => {
  shareText.value = `${nowTopic.value.title}，${t('share.code')}：${nowTopic.value.code}`
  shareUrl.value = `${location.href}`

  if (isSupported.value) {
    share({
      title: nowTopic.value.title,
      text: shareText.value,
      url: shareUrl.value,
    })
  } else {
    showPane.value = 'Share'
  }
}

const menus = [
  { name: t('headerSocial.menuEdit'), icon: PencilSquareIcon },
  { name: t('headerSocial.menuCopy'), icon: ChatBubbleLeftRightIcon },
  { name: t('headerSocial.menuExport'), icon: DocumentArrowDownIcon },
  { name: t('headerSocial.menuDelete'), icon: TrashIcon, class: 'text-red-700 hover:text-red-500' },
]

const dropDown = ref()
onClickOutside(dropDown, () => {
  showMenu.value = false
})


onMounted(() => {
  useScrollListener(
    '.content', 200,
    () => {
      document.querySelector('.header-content').classList.remove('scroll-hidden')
      document.querySelector('.header-content').classList.add('scroll-show')
    },
    () => {
      document.querySelector('.header-content').classList.remove('scroll-show')
      document.querySelector('.header-content').classList.add('scroll-hidden')
    }
  )
})
</script>
  
<style lang="sass" scoped>
  
  .scroll-hidden
    @apply opacity-0 -translate-y-10
  .scroll-show
    @apply opacity-100 translate-y-0
  
  .action-hidden
    @apply opacity-0 -translate-y-10 w-0
  
  .action-show
    @apply opacity-100 translate-y-0 w-auto
  </style>
  