<template>
  <UINavPane @close="emits('close')" color="#14b8a6">
    <PaneCardDeck
      :page="cardDeckPage"
      ref="cardDeckPane"
      @success="
        code => {
          $router.push(`/deck/${code}`)
        }
      "
    />

    <template #title>
      <div class="flex items-center gap-1">
        <span>{{ $t('paneCardDeck.paneTitle') }}</span>
        <span v-if="cardDeckField.code !== ''" class="px-2 text-sm text-white bg-cyan-500 rounded-lg">
          {{ $t('deckBuilder.editing') }}
        </span>
      </div>

      <span class="text-sm text-white/80 font-normal">
        {{ $t('paneCardDeck.titleSelect') }} {{ cardDeckCount }} {{ $t('paneCardDeck.titleUnit') }}，{{ $t('paneCardDeck.totalPrice') }} ¥{{
          cardDeckPrice
        }}
      </span>
    </template>

    <template #toolbar>
      <UIButton class="flex-none btn-sm bg-black text-white hover:bg-red-500" @click="doClear">
        <TrashIcon />
      </UIButton>
    </template>

    <template #footer>
      <button
        v-if="cardDeckPage === 1"
        @click="cardDeckPage += 1"
        :disabled="cardDeckCount === 0"
        class="action bg-amber-600 hover:bg-amber-500 shadow shadow-amber-500/50 text-white"
      >
        <span class="label"> {{ $t('paneCardDeck.btnNext') }} </span>
        <ArrowRightIcon class="size-5" />
      </button>

      <div v-if="cardDeckPage === 2" class="flex items-center gap-2">
        <button @click="cardDeckPage -= 1" :disabled="cardDeckCount === 0" class="action !w-fit flex-none text-sm text-cyan-500 hover:text-cyan-400">
          <ArrowLeftIcon class="size-5" />
          <span class="label"> {{ $t('paneCardDeck.btnPrev') }} </span>
        </button>

        <button
          :disabled="cardDeckCount === 0"
          class="action bg-amber-600 hover:bg-amber-500 shadow shadow-amber-500/50 text-white"
          @click="doAddDeck"
        >
          <span class="label"> {{ $t('paneCardDeck.btnFinish') }} </span>
          <PaperAirplaneIcon class="size-5" />
        </button>
      </div>
    </template>
  </UINavPane>
</template>

<script setup>
const emits = defineEmits(['close'])
import { ArrowLeftIcon, ArrowRightIcon, PaperAirplaneIcon, ChevronDownIcon, TrashIcon } from '@heroicons/vue/24/outline'

const deckStore = useDeckStore()
const { doCardDeck, resetCardDeck, resetCardDeckField } = deckStore
const { cardDecks, cardDeckPrice, cardDeckCount, cardDeckField } = storeToRefs(deckStore)

const { showNavPane } = storeToRefs(usePreferenceStore())

const cardDeckPage = ref(1)
const { messageConfirm } = useSwal()
const { t } = useNuxtApp().$i18n

// action: doAddDeck
const cardDeckPane = ref()
const doAddDeck = () => {
  if (cardDeckPane.value) cardDeckPane.value.addDeck()
}

// action: doClear
const doClear = async () => {
  const { value } = await messageConfirm(t('deckBuilder.clearDeck'), t('deckBuilder.clearDeckInfo'))
  if (value) {
    resetCardDeck()
    resetCardDeckField()
    cardDeckPage.value = 1
  }
}
</script>

<style lang="sass" scoped>
.action
  @apply w-full p-2 flex justify-center items-center gap-1 rounded-xl
  .label
    @apply hidden md:block font-bold
</style>
