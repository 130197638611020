<template>
  <nav class="nav-container" :style="{ width: navWidth + 'px' }">
    <!-- content -->
    <slot />

    <!-- resize-bar -->
    <!-- <UIResizebar v-model="navWidth" :min="props.min" :max="props.max" /> -->
  </nav>
</template>

<script setup>
const props = defineProps({
  width: {
    type: Number,
    default: 270
  }
})

watch(
  () => props.width,
  val => {
    navWidth.value = val
  }
)

const navWidth = ref(270)
</script>

<style lang="sass" scoped>
.nav-container
  grid-area: nav-bar
  @apply flex flex-col min-h-0 relative z-[4] bg-black
</style>
