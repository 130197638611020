<template>
  <section class="h-full flex flex-col gap-2 pb-4 text-white">
    <button
      v-if="cardDeckField.code !== ''"
      class="rounded-lg mt-4 p-2 bg-cyan-500 text-white flex items-center justify-center gap-2"
      @click="cardDeckField.code = ''"
    >
      <ArrowPathIcon class="size-6" />
      {{ $t('paneCardDeck.changeToCreate') }}
    </button>

    <!-- card group -->
    <div class="pb-8" v-if="props.page === 1">
      <!-- tool bar -->
      <div class="sticky z-20 top-0 py-2 -mx-4 px-4 mb-2 flex-none backdrop-blur-lg border-b border-white/10">
        <UITab :tabs="groupTabs" :now="setting.deckDefaultGroup" @change="e => (setting.deckDefaultGroup = e)" />

        <div class="mt-2 gap-2 flex items-center">
          <UIPopover
            class="flex-none"
            :name="`${isStack ? $t('paneCardDeck.close') : $t('paneCardDeck.open')} ${$t('paneCardDeck.stack')}`"
            :disabled="matchSize('md')"
          >
            <button
              @click="isStack = !isStack"
              :class="{
                'hover:bg-zinc-300 bg-zinc-200 text-zinc-900': isStack,
                'text-white bg-black/70': !isStack
              }"
              class="p-1 rounded-full"
            >
              <Square3Stack3DIcon class="size-6" />
            </button>
          </UIPopover>

          <UIPopover
            class="flex-none"
            :name="`${isPrice ? $t('paneCardDeck.close') : $t('paneCardDeck.open')} ${$t('paneCardDeck.price')}`"
            :disabled="matchSize('md')"
          >
            <button
              @click="isPrice = !isPrice"
              :class="{
                'hover:bg-zinc-300 bg-zinc-200 text-zinc-900': isPrice,
                'text-white bg-black/70': !isPrice
              }"
              class="p-1 rounded-full"
            >
              <BanknotesIcon class="size-6" />
            </button>
          </UIPopover>

          <span class="font-mono flex-none text-zinc-500/50"> | </span>

          <UIPopover class="flex-none" :name="$t('paneCardDeck.addCardMode')" :disabled="matchSize('md')">
            <UIButton
              class="btn-sm"
              :class="{
                'bg-green-500 text-white': editMode === 'add',
                'bg-black/70 text-white hover:bg-zinc-700': editMode !== 'add'
              }"
              @click="editMode = editMode === 'add' ? '' : 'add'"
            >
              <PlusIcon :class="{ 'stroke-2': editMode === 'add' }" />
            </UIButton>
          </UIPopover>

          <UIPopover class="flex-none" :name="$t('paneCardDeck.deleteCardMode')" :disabled="matchSize('md')">
            <UIButton
              class="btn-sm"
              :class="{
                'bg-red-500 text-white': editMode === 'minus',
                'bg-black/70 text-white hover:bg-zinc-700': editMode !== 'minus'
              }"
              @click="editMode = editMode === 'minus' ? '' : 'minus'"
            >
              <MinusIcon :class="{ 'stroke-2': editMode === 'minus' }" />
            </UIButton>
          </UIPopover>

          <template v-if="user.role === 'member'">
            <span class="font-mono flex-none text-zinc-500/50"> | </span>

            <StarIcon class="size-6 text-yellow-500" />

            <UIPopover class="flex-none" :name="$t('paneCardDeck.lowerPrice')" :disabled="matchSize('md')">
              <button
                class="p-1 rounded-full hover:bg-green-500 text-white bg-black/70 disabled:opacity-50"
                @click="swapAllCards('low')"
                :disabled="swapping"
              >
                <ArrowTrendingDownIcon class="size-6" />
              </button>
            </UIPopover>

            <UIPopover class="flex-none" :name="$t('paneCardDeck.higherPrice')" :disabled="matchSize('md')">
              <button
                class="p-1 rounded-full hover:bg-red-500 text-white bg-black/70 disabled:opacity-50"
                @click="swapAllCards('high')"
                :disabled="swapping"
              >
                <ArrowTrendingUpIcon class="size-6" />
              </button>
            </UIPopover>

            <span class="font-mono flex-none text-zinc-300"> {{ $t('paneCardDeck.cache') }}：{{ swapPool.length }} </span>
          </template>

          <!-- <span class="ml-auto flex-none font-mono text-zinc-500/50"> | </span>

          <UIButton class="btn-sm bg-black text-white">
            <img src="/soul.gif" class="size-6" />
          </UIButton>
          <span class="font-mono flex-none text-zinc-500/50"> {{ souls }} </span> -->
        </div>
      </div>
      <CardNavGroup :isStack="isStack" :isPrice="isPrice" :cards="showCards" @select="e => selectCard(e)" :mode="editMode">
        <!-- 卡片稀有度工具列 -->
        <template #cardRare="{ card }">
          <div v-if="user.role === 'member'" @click.stop="" class="mt-2 flex items-center justify-center gap-1">
            <SparklesIcon class="size-4 text-zinc-300" />
            <button
              v-for="low in card.lowVer"
              :key="'low-' + low"
              @click.stop="swapCard(card, low)"
              class="rounded-full h-2 w-6 border-zinc-300 border"
            />

            <div class="rounded-full h-2 w-6 border-zinc-300 bg-zinc-300 border" />

            <button
              v-for="high in card.highVer"
              :key="'high-' + high"
              @click.stop="swapCard(card, high)"
              class="rounded-full h-2 w-6 border-zinc-300 border"
            />
          </div>
        </template>
      </CardNavGroup>
    </div>

    <!-- deck info -->
    <div v-if="props.page === 2">
      <div class="py-4 flex flex-col gap-4">
        <div class="flex flex-col gap-2" v-for="q in qs" :key="q.field">
          <div class="flex items-center gap-2">
            <FlagIcon class="size-5 stroke-2" />
            <span class="font-bold">{{ $t(`paneCardDeck.${q.field}`) }}：</span>
          </div>
          <div
            class="w-full flex items-center gap-2 p-2 rounded-xl default-transition ring-2 ring-zinc-800/80 bg-zinc-900/50"
            :class="{
              'text-zinc-500': isLoading,
              'text-white hover:bg-zinc-800/70 focus-within:bg-zinc-800/70 active:bg-zinc-800/70': !isLoading
            }"
          >
            <UIInput :disabled="isLoading" :type="q.type" :placeholder="$t(`paneCardDeck.${q.field}Placeholder`)" v-model="cardDeckField[q.field]" />
          </div>
          <span class="text-zinc-400 text-sm" v-if="q.field === 'title'">
            {{ $t('paneCardDeck.info1') }}
            <a class="cursor-pointer text-blue-500 hover:underline" @click="routeToSetting"> {{ $t('user.preference') }} </a>
          </span>
        </div>

        <div class="flex flex-col gap-2">
          <div class="flex items-center gap-2">
            <FlagIcon class="size-5 stroke-2" />
            <span class="font-bold">{{ $t('paneCardDeck.cover') }}：{{ cardDeckField.cover }}</span>
          </div>

          <div v-if="deckImages.length > 0" class="grid grid-cols-1 md:grid-cols-2 gap-2 rounded-xl ring-2 ring-zinc-800/80 p-2">
            <button
              class="w-full flex items-center gap-2 rounded-lg"
              :disabled="isLoading"
              :class="{
                'text-zinc-500': isLoading,
                'hover:bg-zinc-800/70': !isLoading && cardDeckField.cover !== image.scraper,
                'bg-cyan-800': !isLoading && cardDeckField.cover === image.scraper,
                'bg-zinc-800': isLoading && cardDeckField.cover === image.scraper
              }"
              v-for="image in deckImages"
              :key="image.id"
              @click="cardDeckField.cover = image.scraper"
            >
              <img
                class="flex-none w-16 h-16 rounded-lg aspect-square object-top object-cover select-none"
                @error="
                  e => {
                    e.target.src = '/images/cover.png'
                  }
                "
                :src="image.cover"
              />
              <div class="w-full overflow-hidden text-left select-none">
                <span class="block truncate">{{ translate(image, 'title') }}</span>
                <small class="text-zinc-400 truncate">{{ image.id }}</small>
              </div>
            </button>
          </div>
          <span class="text-zinc-500" v-else>{{ $t('empty') }}</span>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import * as _ from 'lodash'
import {
  BanknotesIcon,
  Square3Stack3DIcon,
  FlagIcon,
  ArrowPathIcon,
  PlusIcon,
  MinusIcon,
  SparklesIcon,
  ArrowTrendingDownIcon,
  ArrowTrendingUpIcon
} from '@heroicons/vue/24/outline'
import { StarIcon } from '@heroicons/vue/24/solid'

const emits = defineEmits(['success'])

const deckStore = useDeckStore()
const { doCardDeck, resetCardDeck, resetCardDeckField, putDeck, createDeck } = deckStore
const { swapPool, cardDecks, cardDeckField, editMode } = storeToRefs(deckStore)
const cardtore = useCardStore()
const { nowCard } = storeToRefs(cardtore)
const { getCardInfo } = cardtore
const { groupCards, formatCardsMap, genBuildDeckName } = useCard()
const { matchSize } = useRwd()
const preferenceStore = usePreferenceStore()
const { translate } = preferenceStore
const { setting, showPane, groupTabs } = storeToRefs(preferenceStore)
const { t } = useNuxtApp().$i18n
const router = useRouter()
const { messageError } = useSwal()
const { user } = storeToRefs(useGlobalStore())

const isStack = ref(true)
const isPrice = ref(false)
const isLoading = ref(false)

const props = defineProps({
  page: {
    type: Number,
    default: 1
  }
})

watch(
  () => props.page,
  val => {
    if (document.querySelector('.pane-content')) {
      document.querySelector('.pane-content').scrollTo(0, 0)
    }

    if (val === 2) {
      cardDeckField.value.title = cardDeckField.value.title || genBuildDeckName(formatCardsMap(cardDecks.value), setting.value.deckDefaultName)
    }
  }
)

const routeToSetting = () => {
  showPane.value = null
  setTimeout(() => {
    router.push({ path: '/user', query: { open: 'setting' } })
  }, 500)
}

// action: swapCard
const swaping = ref(false)

const swapAllCards = async type => {
  if (swaping.value) return

  swaping.value = true
  await Promise.all(
    showCards.value
      .filter(e => e.id)
      .map(async e => {
        const targetId = type === 'low' ? new Array(...e.lowVer).pop() : e.highVer[0]
        if (targetId) await doSwap(e, targetId)
      })
  )
  swaping.value = false
}

const swapCard = async (card, targetId) => {
  if (swaping.value) return

  swaping.value = true
  await doSwap(card, targetId)
  swaping.value = false
}

const doSwap = async (from, cardid) => {
  let target = showCards.value.filter(e => e.id).find(e => e.id === cardid)
  // chech is it in pool
  target = target || swapPool.value.find(e => e.id === cardid)

  // find target card
  if (!target) {
    const res = await getCardInfo(cardid)
    if (res) {
      swapPool.value.push(res)
      target = res
    } else {
      swaping.value = false
      return
    }
  }

  // do swap
  doCardDeck('minus', from)
  doCardDeck('add', target)
}

// action: addDeck

const addDeck = async () => {
  const cards = Array.from(cardDecks.value.entries())
    .map(e => new Array(e[1].count).fill(e[1].card.id))
    .flat()
  if (cards.length <= 0) {
    messageError(t('paneCardDeck.fail'), t('paneCardDeck.addCardInfo'))
    return
  }
  const infos = _.entries(_.groupBy(formatCardsMap(cardDecks.value).map(e => e.color))).map(e => ({
    color: e[0],
    count: e[1].length
  }))

  const data = { ...cardDeckField.value, cards, infos }

  isLoading.value = true

  const res = await (cardDeckField.value.code === '' ? createDeck(data) : putDeck(cardDeckField.value.code, data))

  if (cardDeckField.value.code === '') {
    refreshNuxtData('deckCode')
  }
  isLoading.value = false

  if (res) {
    console.log(res)
    resetCardDeck()
    resetCardDeckField()
    emits('success', res.code)
  }
}

// deck info
const qs = [
  { name: '標題', field: 'title', type: 'text', placeholder: '為你的牌組命名吧' },
  { name: '內容', field: 'description', type: 'textarea', placeholder: '用一段話介紹一下你的牌組' }
]

// const souls = computed(() => Array.from(cardDecks.value.entries())
//   .map(e => e[1].count * (e[1].card.trigger.includes('soul') ? 1 : (e[1].card.trigger.includes('soul2') ? 2 : 0)))
//   .reduce((a, b) => a + b, 0)
// )

// container working config
const uniqueCards = computed(() => Array.from(cardDecks.value.entries()).map(e => e[1].card))

const selectCard = card => {
  if (editMode.value) doCardDeck(editMode.value, card)
  else {
    nowCard.value = card
  }
}

const showCards = computed(() =>
  groupCards(
    formatCardsMap(cardDecks.value),
    groupTabs.value.find(e => e.field === setting.value.deckDefaultGroup)
  )
)

const deckImages = computed(() =>
  uniqueCards.value.map(e => ({
    cover: useImageProxy(e.scraper),
    title: e.title,
    i18n: e.i18n,
    id: e.id,
    scraper: e.scraper
  }))
)

defineExpose({ addDeck })
</script>
