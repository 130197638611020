<template>
  <div class="w-full flex items-center gap-2">
    <ClientOnly>
      <div class="grow-1 w-full md:w-fit min-w-0 p-2 rounded-full bg-white text-zinc-700 shadow shadow-zinc-900/50 flex items-center gap-1">
        <MagnifyingGlassIcon class="flex-none size-5 stroke-2 cursor-pointer text-zinc-700" @click.stop="doSearch(keyword, nowSeries)" />
        <input
          class="text-sm p-0 bg-transparent border-none focus:ring-0 placeholder:text-zinc-500 placeholder:font-light w-full min-w-0"
          type="text"
          :placeholder="$t('headerSocial.searchPlaceholder')"
          v-model="keyword"
          @keyup.enter="doSearch(keyword, nowSeries)"
        />
        <XMarkIcon class="flex-none size-5 stroke-2 cursor-pointer text-zinc-700" @click.stop="doSearch('', nowSeries)" />
      </div>

      <div class="relative">
        <button
          class="flex-none p-2 rounded-full flex items-center gap-1 shadow font-bold text-zinc-700"
          :class="{
            'bg-gradient-to-r from-teal-300 to-blue-300 shadow-cyan-900/50': nowSeries,
            'bg-white shadow-zinc-900/50': !nowSeries
          }"
          @click.stop="showMenu = true"
        >
          <Square2StackIcon class="flex-none size-5 stroke-2" />
          <span class="text-sm hidden md:block">
            {{ nowSeries ? translate(nowSeries, 'name') : $t('headerSocial.filterSeries') }}
          </span>
          <span class="text-sm font-mono block md:hidden">{{ nowSeries ? nowSeries.code[0] : 'CODE' }}</span>
          <XMarkIcon class="flex-none size-5 stroke-2 cursor-pointer" @click.stop="doSearch('', null)" />
        </button>
        <TransitionCollapse>
          <UIDropDown
            ref="dropDown"
            :position="position"
            :menus="menus"
            :isSearch="true"
            :parentSize="42"
            v-if="showMenu"
            @select="
              e => {
                doSearch(keyword, e.series)
                showMenu = false
              }
            "
            :findFn="
              (e, k) => {
                return [e.name, e.series?.name, e.series?.i18n?.zh?.name, e.series?.code].join('').toLocaleLowerCase().includes(k.toLocaleLowerCase())
              }
            "
          />
        </TransitionCollapse>
      </div>
      <div class="ml-auto">
        <button
          @click.stop="$router.push('/social/add')"
          class="p-2 rounded-full flex items-center gap-1 shadow font-bold text-zinc-700 bg-white shadow-zinc-900/50"
        >
          <PencilSquareIcon class="size-5" />
          <span class="text-sm hidden md:block">
            {{ $t('headerSocial.addArticle') }}
          </span>
        </button>
      </div>
    </ClientOnly>
  </div>
</template>

<script setup>
import { MagnifyingGlassIcon, ShareIcon, XMarkIcon, Square2StackIcon, PencilSquareIcon } from '@heroicons/vue/24/outline'
import { Square2StackIcon as SolidSquare2StackIcon } from '@heroicons/vue/24/solid'
import { onClickOutside } from '@vueuse/core'

const keyword = ref('')
const showMenu = ref(false)
const { series } = storeToRefs(useSeriesStore())
const { translate } = usePreferenceStore()
const { t } = useNuxtApp().$i18n

const menus = computed(() => [
  { name: t('seriesDefalut'), series: null, icon: SolidSquare2StackIcon },
  ...(series.value || []).map(e => ({
    name: translate(e, 'name') || e.name,
    series: e,
    icon: Square2StackIcon
  }))
])

const nowSeries = ref(null)
const route = useRoute()
const { matchSize } = useRwd()

const position = computed(() => {
  return matchSize(['xs', 'sm', 'md']) ? { right: 0, top: 0 } : { left: 0, top: 0 }
})

const dropDown = ref()
onClickOutside(dropDown, () => {
  showMenu.value = false
})

const doSearch = (k, s) => {
  showMenu.value = false
  nowSeries.value = s
  keyword.value = k

  const q = {}
  if (k) q['k'] = k
  if (s) q['s'] = s?.id

  setTimeout(async () => {
    await navigateTo({ path: '/social', query: q })
  }, 500)
}

watch(
  () => route.query,
  () => {
    nowSeries.value = series.value.find(e => e.id === Number(route.query.s))
    keyword.value = route.query.k
  },
  { immediate: true }
)
</script>
