<template>
  <section class="m-2 rounded-2xl bg-base overflow-auto h-full flex flex-col" :style="bgPaneStyle">
    <header
      class="px-4 py-2 sticky top-0 z-20 flex items-center"
      :style="{
        background: props.color === '' ? '#18181b' : props.color + '50'
      }"
    >
      <!-- header -->
      <h2 class="grow-1 w-full text-white text-xl font-black flex flex-col">
        <slot name="title" />
      </h2>

      <!-- toolbar -->
      <div class="flex-none flex gap-2 justify-end">
        <slot name="toolbar" />
        <button class="flex-none p-1 rounded-full bg-black/70 hover:bg-white hover:text-black text-white" @click="doClose">
          <XMarkIcon class="h-6 w-6" />
        </button>
      </div>
    </header>

    <div class="pane-content px-4 w-full h-max overflow-x-hidden">
      <slot />
    </div>

    <footer class="mt-auto p-4 flex-none">
      <slot name="footer" />
    </footer>
  </section>
</template>

<script setup>
import { XMarkIcon } from '@heroicons/vue/24/outline'

const emits = defineEmits(['close'])
const { orientation } = useRwd()
const { messageConfirm } = useSwal()
const { t } = useNuxtApp().$i18n

const props = defineProps({
  blank: {
    type: Boolean,
    default: false
  },
  confirm: {
    type: Boolean,
    default: false
  },
  color: {
    type: String,
    default: ''
  }
})

const bgPaneStyle = computed(() => {
  return {
    background: props.color === '' ? '#121212' : 'linear-gradient(180deg, ' + props.color + '70 100px, #121212 70%)'
  }
})

const doClose = async () => {
  if (props.confirm) {
    const { value } = await messageConfirm(t('pane.leaveTitle'), t('pane.leaveInfo'))
    if (value) {
      emits('close')
    }
  } else {
    emits('close')
  }
}
</script>
