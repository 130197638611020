<template>
  <div class="h-full flex flex-col gap-2 pb-4 text-white divide-y divide-white/10 min-h-[20rem]">
    <!-- step 1 -->
    <section class="mb-4">
      <h3 class="topic my-4">
        選擇來源
        <span class="subtitle">選擇你的牌組代碼來源</span>
      </h3>
      <div class="grid grid-cols-2 md:grid-cols-4 gap-2">
        <button v-for="item in types" :key="item.name" class="item default-transition"
          :class="{ 'active': nowTypes.field === item.field }" @click="nowTypes = item">
          <component :is="item.icon" class="icon size-6" />
          <span> {{ item.name }} </span>
        </button>
      </div>
    </section>

    <!-- step 2 -->
    <section class="mb-4">
      <h3 class="topic my-4">
        輸入代碼
      </h3>
      <div class="grid grid-cols-1 gap-2">
        <div class="item default-transition">
          <UIInput type="text" placeholder="代碼" v-model="nowCode" />
        </div>
      </div>


      <div class="mt-8 flex justify-center">
        <button class="item md:!w-[20rem] justify-center default-transition" @click="doRouter" :disabled="nowCode === ''">
          <CheckIcon class="icon size-6" />
          <span> 設定完成 </span>
        </button>
      </div>
    </section>

  </div>
</template>

<script setup>
import { GlobeAsiaAustraliaIcon, BookmarkIcon, PaperAirplaneIcon, ArrowPathIcon, CheckIcon, XMarkIcon, ExclamationCircleIcon, DocumentIcon, PhotoIcon, TableCellsIcon, CubeIcon, SquaresPlusIcon, ArrowDownTrayIcon } from '@heroicons/vue/24/outline'
const router = useRouter()
const { showPane } = storeToRefs(usePreferenceStore())

const types = [
  { name: '牌組', field: 'deck', icon: BookmarkIcon },
  { name: '社群', field: 'social', icon: GlobeAsiaAustraliaIcon },
  { name: 'DeckLog JP', field: 'decklog', icon: SquaresPlusIcon },
  { name: 'DeckLog EN', field: 'decklogEn', icon: SquaresPlusIcon },
]

const nowTypes = ref(types[0])
const nowCode = ref('')

const doRouter = () => {
  showPane.value = null
  if (nowTypes.value.field === 'decklogEn')
    router.push({ path: `/decklog/${nowCode.value}`, query: { en: true } })
  else
    router.push(`/${nowTypes.value.field}/${nowCode.value}`)
}
</script>

<style lang="sass" scoped>

.topic
  @apply text-lg font-bold mr-auto text-zinc-200
  .subtitle
    @apply block font-normal text-zinc-400 text-xs

.item
  @apply w-full flex items-center gap-2 p-2 rounded-xl ring-2 ring-zinc-800
  &:not(.active)
    @apply bg-zinc-900/50 text-zinc-400 hover:bg-zinc-800 focus-within:bg-zinc-800 active:bg-zinc-800 disabled:text-zinc-800
  &.active
    @apply bg-gradient-to-r from-blue-500 to-cyan-500 shadow-sky-500/50 text-white
  
  .icon
    @apply flex-none stroke-2
</style>