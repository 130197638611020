<template>
  <div class="w-screen md:w-[50vw] h-screen md:h-[80vh] md:rounded-2xl overflow-hidden flex flex-col gap-2 text-white divide-y divide-white/10">
    <section class="h-full relative">
      <!-- video background -->
      <div class="absolute select-none z-0 inset-0 flex flex-col bg-black">
        <video ref="video" class="h-full w-full object-cover object-center" playsinline autoplay muted />
      </div>

      <!-- capture photo -->
      <div class="absolute select-none z-1 inset-0 mt-[80px]">
        <div
          class="mx-auto w-[300px] h-[calc(300px*889/635)] ring-4 rounded-2xl shadow-[0_-5px_60px]"
          :class="{
            'ring-pink-600 shadow-pink-600': !isActive,
            'ring-green-600 shadow-green-600': isActive
          }"
        >
          <div class="pt-2 pl-2">
            <img :src="preview" class="h-[3rem] w-auto aspect-card" />
          </div>
        </div>
      </div>

      <!-- main ui -->
      <div class="absolute select-none z-1 inset-0 flex flex-col gap-2 px-4 pt-4 pb-8">
        <!-- top ui -->
        <div class="flex items-center gap-2">
          <!-- dropdown series -->
          <div class="relative">
            <button
              class="flex-none p-2 rounded-full flex items-center gap-1 shadow font-bold text-zinc-700"
              :class="{
                'bg-gradient-to-r from-teal-300 to-blue-300 shadow-cyan-900/50': nowSeries,
                'bg-white shadow-zinc-900/50': !nowSeries
              }"
              @click.stop="showMenu = true"
            >
              <Square2StackIcon class="flex-none size-5 stroke-2" />
              <span class="text-sm hidden md:block truncate">
                {{ nowSeries ? translate(nowSeries, 'name') : $t('headerSocial.filterSeries') }}
              </span>
              <span class="text-sm font-mono block md:hidden">{{ nowSeries ? nowSeries.code[0] : 'CODE' }}</span>
              <XMarkIcon
                class="flex-none size-5 stroke-2 cursor-pointer"
                @click.stop="
                  e => {
                    nowSeries = null
                    seriesSet = []
                  }
                "
              />
            </button>
            <TransitionCollapse>
              <UIDropDown
                ref="dropDown"
                :position="position"
                :menus="menus"
                :isSearch="true"
                :parentSize="42"
                v-if="showMenu"
                @select="
                  e => {
                    selectSeries(e.series)
                    showMenu = false
                  }
                "
                :findFn="
                  (e, k) => {
                    return [e.name, e.series?.name, e.series?.i18n?.zh?.name, e.series?.code]
                      .join('')
                      .toLocaleLowerCase()
                      .includes(k.toLocaleLowerCase())
                  }
                "
              />
            </TransitionCollapse>
          </div>

          <!-- predict info -->
          <button
            class="w-full truncate select-none rounded-2xl bg-black/50 px-4 py-1 text-left text-white"
            @click="maybe ? selectSeries(maybe) : false"
          >
            {{ info }}
          </button>

          <!-- close pane -->
          <button class="flex-none p-1 rounded-full bg-black/70 hover:bg-white hover:text-black text-white" @click="showPane = null">
            <XMarkIcon class="h-6 w-6" />
          </button>
        </div>

        <!-- bottom ui -->
        <div class="mt-auto md:ml-auto md:w-[300px] grid grid-cols-3 gap-2 md:gap-4 bg-black/50 rounded-xl p-4 overflow-hidden" @click="pause">
          <div v-for="predict in predicts" :key="predict.image" class="flex flex-col items-center" @click="openCard(predict.image)">
            <small class="select-none text-white">{{ predict.image }}</small>
            <CardCover :src="predict.image" :isEffect="false" />
            <small class="select-none text-zinc-300">{{ predict.distance.toFixed(2) }}</small>
          </div>
        </div>

        <button class="flex items-center gap-2 select-none bg-white text-black p-4 rounded-xl" ref="triggerBtn">
          <CameraIcon class="size-6" />
          <span class="mx-auto select-none">按住開啟自動偵測</span>
          <PlayIcon class="size-6" v-if="!isActive" />
          <PauseIcon class="size-6" v-else />
        </button>
      </div>
    </section>
  </div>
</template>

<script setup>
import { CameraIcon, PlayIcon, PauseIcon, XMarkIcon, Square2StackIcon } from '@heroicons/vue/24/outline'
import { Square2StackIcon as SolidSquare2StackIcon } from '@heroicons/vue/24/solid'
import { useUserMedia, useIntervalFn } from '@vueuse/core'

const { series } = storeToRefs(useSeriesStore())
const cardStore = useCardStore()
const { nowCard, cardSet } = storeToRefs(cardStore)
const { getCardInfo } = cardStore
const preferenceStore = usePreferenceStore()
const { showPane } = storeToRefs(preferenceStore)
const { translate } = preferenceStore

const { t } = useNuxtApp().$i18n
const { postDetection } = useCardDetection()
const { doFetch } = useFetchApi()
const { matchSize } = useRwd()

// menus
const showMenu = ref(false)
const nowSeries = ref(null)
const position = { left: 0, top: 0 }

const menus = computed(() => [
  { name: t('seriesDefalut'), series: null, icon: SolidSquare2StackIcon },
  ...(series.value || []).map(e => ({
    name: translate(e, 'name') || e.name,
    series: e,
    icon: Square2StackIcon
  }))
])

// camera
const video = ref()

const { stream, start, enabled } = useUserMedia({
  constraints: {
    audio: false,
    video: {
      facingMode: 'environment'
    }
  }
})

watchEffect(() => {
  if (video.value) video.value.srcObject = stream.value
})

// capture photo
const preview = ref()
const predicts = ref([
  { image: '', distance: 0 },
  { image: '', distance: 0 },
  { image: '', distance: 0 }
])
const info = ref('辨識系列資訊')
const maybe = ref()
const block = ref(false)

const capturePhoto = async () => {
  if (block.value) return

  block.value = true
  const videoCanvas = genCaptureCanvas()
  preview.value = videoCanvas.toDataURL('image/jpeg')

  // make a blob from videoCanvas
  let blob = await new Promise(resolve => videoCanvas.toBlob(resolve, 'image/jpeg'))

  let res = await postDetection(blob, nowSeries.value ? nowSeries.value.id : 'all')
  block.value = false

  if (res) {
    calcMaybe(res)
  } else {
    pause()
  }
}

const genCaptureCanvas = () => {
  let screenW = video.value.clientWidth
  let screenH = video.value.clientHeight
  let videoW = video.value.videoWidth
  let videoH = video.value.videoHeight
  let scale = videoH / screenH
  let paddingTop = 80

  let w = 300 * scale
  let h = (w * 889) / 635
  let x = (-1 * (videoW - w)) / 2
  let y = -1 * paddingTop * scale

  let videoCanvas = document.createElement('canvas')
  videoCanvas.height = h
  videoCanvas.width = w
  let videoContext = videoCanvas.getContext('2d')

  videoContext.drawImage(video.value, x, y)
  return videoCanvas
}

const calcMaybe = res => {
  predicts.value = [...(res || [])].slice(0, 3)

  if (nowSeries.value) {
    info.value = `你選的是 ${translate(nowSeries.value, 'name')}`
    maybe.value = null
  } else {
    // score is all res distanse value normalized to (0,1)
    let score = res.map(e => e.distance)
    let maxDistance = Math.max(...score)
    let minDistance = Math.min(...score)
    score = score.map(distance => 1 - (distance - minDistance) / (maxDistance - minDistance))

    let mostLikely = (res || []).map((e, i) => [series.value.find(s => s.code.includes(e.image.split('_')[0])), score[i]])

    let mode = mostLikely
      .reduce((a, c) => {
        const ids = a.find(e => e.id === c[0].id)
        if (ids) {
          ids.count = ids.count + c[1]
        } else {
          a.push({ ...c[0], count: c[1] })
        }
        return a
      }, [])
      .sort((a, b) => b.count - a.count)[0]

    console.log(mode)
    if (mode.count >= 1) {
      info.value = `可能是 ${translate(mode, 'name')}`
      maybe.value = mode
    } else {
      info.value = `無法判斷可能的系列`
      maybe.value = null
    }
  }
}

// open card
const seriesSet = ref([])

const selectSeries = async series => {
  nowSeries.value = series
  seriesSet.value = await getSeries(series.id)
  console.log(seriesSet.value)
}

const getSeries = async id => {
  return await doFetch({ type: 'card', input: id })
}

const openCard = async image => {
  if (!image) return

  // image format is "XXX_XXX_XXX"
  // transform to "XXX/XXX-XXX" for id
  const id = image.replace(/_/, '/').replace(/_/, '-')

  if (seriesSet.value.length > 0) {
    nowCard.value = seriesSet.value.find(e => e.id === id)
    return
  }

  const card = await getCardInfo(id)
  cardSet.value = []
  nowCard.value = card
}

// interval
const triggerBtn = ref()
const { pressed } = useMousePressed({ target: triggerBtn })

const { pause, resume, isActive } = useIntervalFn(
  () => {
    capturePhoto()
  },
  1000,
  { immediate: false }
)

watchEffect(
  () => {
    if (pressed.value) {
      resume()
    } else {
      pause()
    }
  },
  { immediate: false }
)

onMounted(() => {
  start()

  document.addEventListener('contextmenu', event => {
    enabled.value ? event.preventDefault() : null
  })
})
</script>

<style lang="sass" scoped>

.topic
  @apply text-lg font-bold mr-auto text-zinc-200
  .subtitle
    @apply block font-normal text-zinc-400 text-xs

.item
  @apply w-full flex items-center gap-2 p-2 rounded-xl ring-2 ring-zinc-800
  &:not(.active)
    @apply bg-zinc-900/50 text-zinc-400 hover:bg-zinc-800 focus-within:bg-zinc-800 active:bg-zinc-800 disabled:text-zinc-800
  &.active
    @apply bg-gradient-to-r from-blue-500 to-cyan-500 shadow-sky-500/50 text-white
  .icon
    @apply flex-none stroke-2

.img-preview
  @apply bg-zinc-700 rounded-card flex-none shadow-lg aspect-card min-w-0 w-full select-none
</style>
