<template>
  <section class="fixed z-[60] bottom-0 w-full bg-gradient-to-t from-black via-black/90">
    <div class="px-2 min-h-[2rem]">
      <DeckBuilder />
    </div>
    <nav class="flex items-center justify-center">
      <NuxtLink
        @click="scrollTop"
        v-for="(nav, navid) in navs"
        :key="`tabnav-${navid}`"
        :to="nav.link"
        class="relative w-full flex flex-col gap-1 items-center pt-2 px-3"
        :class="{
          'text-white': isNowNav(nav.link),
          'text-zinc-400 hover:text-white': !isNowNav(nav.link),
          'pb-3': !(matchSize('md') && isIos),
          'pb-8': matchSize('md') && isIos
        }"
      >
        <component :is="isNowNav(nav.link) ? nav.active : nav.icon" class="flex-none w-7 h-7" />
        <span class="text-[9px] font-light truncate">{{ $t('navbar.' + nav.name) }}</span>

        <span
          v-if="nav.name === 'notification' && noReadCount > 0"
          class="absolute top-1 left-1/2 text-xs rounded-full px-1 bg-red-500 text-white pb-[2px]"
        >
          {{ noReadCount }}
        </span>
      </NuxtLink>

      <NuxtLink
        @click="scrollTop"
        :to="user.name ? '/workshop' : '/login'"
        class="w-full flex flex-col gap-1 items-center pt-2 px-3"
        :class="{
          'text-white': isNowNav('/workshop'),
          'text-zinc-400 hover:text-white': !isNowNav('/workshop'),
          'pb-3': !(matchSize('md') && isIos),
          'pb-8': matchSize('md') && isIos
        }"
      >
        <UIUser :user="user" :show="{ head: true, role: true }" bgClass="size-7" textClass="" />

        <!-- <component :is="isNowNav(nav.link) ? nav.active : nav.icon" class="flex-none w-7 h-7" /> -->
        <span class="text-[9px] font-light truncate">
          {{ user?.name ? $t('navbar.workshop') : $t('navbar.login') }}
        </span>
      </NuxtLink>
    </nav>
  </section>
</template>

<script setup>
import UrlPattern from 'url-pattern'
import LogoTitle from '~~/assets/svg/logo-zh.svg'
import {
  HomeIcon as OutlineHomeIcon,
  Square2StackIcon as OutlineSquare2StackIcon,
  BookmarkIcon as OutlineBookmarkIcon,
  GlobeAsiaAustraliaIcon as OutlineGlobeAsiaAustraliaIcon,
  CubeIcon as OutlineCubeIcon,
  AtSymbolIcon as OutlineAtSymbolIcon,
  BellIcon as OutlineBellIcon
} from '@heroicons/vue/24/outline'
import {
  HomeIcon as SolidHomeIcon,
  Square2StackIcon as SolidSquare2StackIcon,
  BookmarkIcon as SolidBookmarkIcon,
  GlobeAsiaAustraliaIcon as SolidGlobeAsiaAustraliaIcon,
  CubeIcon as SolidCubeIcon,
  AtSymbolIcon as SolidAtSymbolIcon,
  BellIcon as SolidBellIcon
} from '@heroicons/vue/24/solid'

const { user, noReadCount } = storeToRefs(useGlobalStore())

const navs = ref([
  { link: '/', title: '首頁', name: 'home', icon: OutlineHomeIcon, active: SolidHomeIcon },
  { link: '/series', title: '卡表', name: 'series', icon: OutlineSquare2StackIcon, active: SolidSquare2StackIcon },
  { link: '/deck', title: '我的牌組', name: 'deck', icon: OutlineBookmarkIcon, active: SolidBookmarkIcon },
  { link: '/social', title: '社群', name: 'social', icon: OutlineGlobeAsiaAustraliaIcon, active: SolidGlobeAsiaAustraliaIcon },
  // { link: '/workshop', title: '工作坊', name: 'workshop', icon: OutlineCubeIcon, active: SolidCubeIcon },
  { link: '/notification', title: '通知', name: 'notification', icon: OutlineBellIcon, active: SolidBellIcon }
])

const route = useRoute()
const { matchSize } = useRwd()
const { isIos } = useDevice()
const isNowNav = link => {
  if (link === '/') return route.path === '/'
  else return new UrlPattern(`${link}(/:id)`).match(route.path)
}
const scrollTop = () => {
  document.querySelector('#main').scrollTo(0, 0)
}
</script>
