<template>
  <div class="relative z-10 flex items-center gap-4">
    <div
      class="default-transition min-w-0"
      :class="{
        'header-content scroll-hidden': !(!matchSize('lg') && setting.theaterMode)
      }"
    >
      <h2 class="truncate font-bold">{{ nowDeck?.title || $t('headerSocial.social') }}</h2>
    </div>
    <div class="ml-auto flex-none action flex items-center gap-2 h-min default-transition">
      <ItemHeaderButton :name="$t('navbar.share')" field="share" @action="doShare">
        <template #icon>
          <ShareIcon class="size-6 stroke-2" />
        </template>
      </ItemHeaderButton>

      <template v-if="!matchSize('md')">
        <ItemHeaderButton v-for="item in menus" :key="item.name" :name="item.name" :field="item.field" :class="item.class" @action="item.action()">
          <template #icon>
            <Component :is="item.icon" class="size-6 stroke-2" />
          </template>
        </ItemHeaderButton>
      </template>

      <ItemHeaderButton
        v-else
        field="detail"
        :class="{
          'bg-zinc-800 text-white': showMenu
        }"
        @action="showMenu = true"
      >
        <template #icon>
          <AdjustmentsHorizontalIcon class="size-6 stroke-2" />
        </template>

        <TransitionCollapse>
          <UIDropDown
            ref="dropDown"
            :position="position"
            :menus="menus"
            v-if="showMenu"
            :parentSize="36"
            @select="
              e => {
                e.action ? e.action() : null
                showMenu = false
              }
            "
          />
        </TransitionCollapse>
      </ItemHeaderButton>
    </div>
  </div>
</template>

<script setup>
import {
  ShareIcon,
  AdjustmentsHorizontalIcon,
  PencilSquareIcon,
  TrashIcon,
  DocumentArrowDownIcon,
  ClipboardDocumentCheckIcon
} from '@heroicons/vue/24/outline'
import { onClickOutside } from '@vueuse/core'

const deckStore = useDeckStore()
const { resetCardDeck, resetCardDeckField, doCardDeck, deleteSocial, setCardDeck } = deckStore
const { nowDeck } = storeToRefs(deckStore)
const { shareText, shareUrl } = storeToRefs(useGlobalStore())
const { setting, showPane } = storeToRefs(usePreferenceStore())

const showMenu = ref(false)

const router = useRouter()
const { messageConfirm } = useSwal()
const { share, isSupported } = useShare()
const { matchSize } = useRwd()
const { t } = useNuxtApp().$i18n

const position = computed(() => {
  return matchSize(['xs', 'sm', 'md']) ? { right: 0, top: 0 } : { left: 0, top: 0 }
})

const doShare = () => {
  shareText.value = `${nowDeck.value.title}，${t('share.code')}：${nowDeck.value.code}`
  shareUrl.value = `${location.href}`

  if (isSupported.value) {
    share({
      title: nowDeck.value.title,
      text: shareText.value,
      url: shareUrl.value
    })
  } else {
    showPane.value = 'Share'
  }
}

const copyDeck = () => {
  // nowDeck to cardDeck
  resetCardDeck()
  resetCardDeckField()

  if (matchSize('md')) {
    nowDeck.value.cards.forEach((card, i) => {
      setTimeout(() => {
        doCardDeck('add', card)
      }, i * 50)
    })
  } else {
    setCardDeck(nowDeck.value.cards)
  }

  router.push({ path: `/series/${nowDeck.value.series[0] ? nowDeck.value.series[0].id : ''}`, query: { open: 'cardDeck' } })
}

const doDeleteDeck = async () => {
  const { value } = await messageConfirm(t('headerSocial.deleteArticle'), `${t('headerSocial.deleteArticleInfo')} "${nowDeck.value?.title}"`)
  if (value) {
    const res = await deleteSocial(nowDeck.value?.code)
    if (res) {
      router.push('/social')
    }
  }
}

const menus = computed(() =>
  [
    { name: t('headerSocial.menuEdit'), icon: PencilSquareIcon, isOwner: true, action: () => router.push(`/social/${nowDeck.value?.code}/edit`) },
    { name: t('headerSocial.menuCopy'), icon: ClipboardDocumentCheckIcon, isCards: true, action: copyDeck },
    {
      name: t('headerSocial.menuExport'),
      icon: DocumentArrowDownIcon,
      isCards: true,
      action: () => router.push({ path: '/workshop', query: { open: 'export', type: 'social', code: nowDeck.value.code } })
    },
    { name: t('headerSocial.menuDelete'), icon: TrashIcon, class: 'text-red-700 hover:text-red-400', action: doDeleteDeck, isOwner: true }
  ]
    .filter(e => (e.isOwner === undefined ? true : nowDeck.value?.isOwner))
    .filter(e => (e.isCards === undefined ? true : nowDeck.value?.cards?.length > 0))
)

// event handler
const dropDown = ref()
onClickOutside(dropDown, () => {
  showMenu.value = false
})

onMounted(() => {
  useScrollListener(
    '.content',
    200,
    () => {
      document.querySelector('.header-content')?.classList.remove('scroll-hidden')
      document.querySelector('.header-content')?.classList.add('scroll-show')
    },
    () => {
      if (!(!matchSize('lg') && setting.value.theaterMode)) {
        document.querySelector('.header-content')?.classList.remove('scroll-show')
        document.querySelector('.header-content')?.classList.add('scroll-hidden')
      }
    }
  )
})
</script>

<style lang="sass" scoped>

.scroll-hidden
  @apply opacity-0 -translate-y-10
.scroll-show
  @apply opacity-100 translate-y-0

.action-hidden
  @apply opacity-0 -translate-y-10 w-0

.action-show
  @apply opacity-100 translate-y-0 w-auto
</style>
