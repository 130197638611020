<template>
  <div class="h-full flex flex-col items-center gap-4">
    <div class="flex items-center gap-2 justify-between">
      <button class="p-2 rounded-xl bg-zinc-800 flex items-center gap-1" @click="emits('cancel')">
        <XMarkIcon class="size-4" />
        <span class="text-sm">取消</span>
      </button>
      <button class="p-2 rounded-xl bg-zinc-800 flex items-center gap-1" @click="openImage">
        <FolderOpenIcon class="size-4" />
        <span class="text-sm">開啟檔案</span>
      </button>
      <button class="p-2 rounded-xl bg-zinc-800 flex items-center gap-1" @click="saveImage" :disabled="!imgCanvas">
        <CheckIcon class="size-4" />
        <span class="text-sm">下一步</span>
      </button>
    </div>
    <Cropper
      class="cropper"
      :src.sync="imageBase64"
      :stencil-props="{ handlers: {}, movable: false, scalable: false }"
      :stencil-size="{ width: 630, height: 880 }"
      image-restriction="stencil"
      @change="change"
    />
  </div>
</template>

<script setup>
import { XMarkIcon, FolderOpenIcon, CheckIcon } from '@heroicons/vue/24/outline'
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'

const emits = defineEmits(['change', 'cancel'])

const imgCanvas = ref()

// image
const postImageData = ref()
const { imageBase64, openImage, postImage } = useUploadImage(
  res => {
    postImageData.value = res
  },
  { doPost: false }
)

const change = ({ coordinates, canvas }) => {
  const n = document.createElement('canvas')
  const nctx = n.getContext('2d')
  n.width = 630
  n.height = 880
  nctx.drawImage(canvas, 0, 0, 630, 880)
  imgCanvas.value = n
}

const saveImage = () => {
  emits('change', imgCanvas.value.toDataURL())
}
</script>

<style lang="sass" scoped>
.cropper
  @apply w-full h-full min-h-[300px] bg-black relative
  &::before
    position: absolute
    top: 0
    z-index: 0
    content: '選擇檔案上傳'
    width: 100%
    height: 100%
    display: flex
    justify-content: center
    align-items: center
    opacity: 0.5
</style>
