export default (deck, options = []) => {
  let id = deck?.cards?.map((e) => e.id)

  if (options.includes('rare')) {
    // 去除 ID 結尾的英文字, ex: 5HY/W83-E001SSP -> 5HY/W83-E001
    id = id.map((e) => e.replace(/[A-Z]+$/, ''))
  }

  if (options.includes('en')) {
    // ID 在 '-' 與 數字間，插入 'E', ex: 5HY/W83-001SSP -> 5HY/W83-E001SSP
    id = id.map((e) => e.replace(/-(\d+)/, '-E$1'))
  }

  if (options.includes('group')) {
    // 計算所有重複的 ID，並產出 ["ID 數量", ...] 陣列
    id = id
      .reduce((acc, cur) => {
        let i = acc.findIndex((e) => e[0].includes(cur))
        if (i === -1) {
          acc.push([cur, 1])
        } else {
          acc[i][1]++
        }
        return acc
      }, [])
      .map((e) => e.join(' '))
  }

  return id.join('\n')
}
