const blobToBase64 = (blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onerror = reject
    reader.onload = () => {
      resolve(reader.result)
    }
    reader.readAsDataURL(blob)
  })
}

const downloadImage = async (image) => {
  try {
    const res = await $fetch(image, { responseType: 'blob' })
    return await blobToBase64(res)
  } catch (e) {
    console.error(e)
    return false
  }
}

const cmToPx = (cm) => {
  return cm * 0.393700787 * 6 * 12
}

const genColumn = (data) => {
  let tmp = {
    columnGap: 5,
    margin: 2,
    columns: [],
  }

  data.forEach((e, i) => {
    if (i < 3) {
      tmp.columns.push({ image: e, width: cmToPx(6.3) })
    }
  })

  return tmp
}

const fonts = {
  Roboto: {
    normal: 'https://jasonxddd.me:9000/bottleneko/fonts/NotoSansTC-Regular.woff2',
    bold: 'https://jasonxddd.me:9000/bottleneko/fonts/NotoSansTC-Bold.woff2',
    italics: 'https://jasonxddd.me:9000/bottleneko/fonts/Roboto-Italic.ttf',
    bolditalics: 'https://jasonxddd.me:9000/bottleneko/fonts/NotoSansTC-Medium.woff2',
  },
}

const deckExportPdf = async (deck, isA4) => {
  try {
    let docDef = {
      pageSize: isA4 ? 'A4' : { width: cmToPx(20.2), height: cmToPx(28.3) },
      pageOrientation: 'portrait',
      pageMargins: 5,
      content: [],
    }

    docDef.content.push(`牌組：${deck.title}，代碼：${deck.code}`)
    let arr = JSON.parse(JSON.stringify(deck.cards))
    arr = await Promise.all(arr.map((e) => downloadImage(e.scraperSrc)))

    const newArr = []
    while (arr.length) newArr.push(arr.splice(0, 3))
    newArr.forEach((e) => {
      docDef.content.push(genColumn(e))
    })

    return await new Promise((resolve) => {
      usePDFMake()
        .createPdf(docDef, null, { ...useFontPresets(), ...fonts })
        .getBlob((blob) => {
          resolve(blob)
        })
    })
  } catch (e) {
    console.error(e)
    return false
  }
}

// cards need base64 string
const customizeExportPdf = async (title, cards, isA4) => {
  try {
    let docDef = {
      pageSize: isA4 ? 'A4' : { width: cmToPx(20.2), height: cmToPx(28.3) },
      pageOrientation: 'portrait',
      pageMargins: 5,
      content: [],
    }

    docDef.content.push(title)
    let arr = JSON.parse(JSON.stringify(cards))
    arr = await Promise.all(arr.map((e) => (e.includes('loading.png') ? downloadImage(e) : e)))

    const newArr = []
    while (arr.length) newArr.push(arr.splice(0, 3))
    newArr.forEach((e) => {
      docDef.content.push(genColumn(e))
    })

    return await new Promise((resolve) => {
      usePDFMake()
        .createPdf(docDef, null, { ...useFontPresets(), ...fonts })
        .getBlob((blob) => {
          resolve(blob)
        })
    })
  } catch (e) {
    console.error(e)
    return false
  }
}

export default () => {
  return {
    deckExportPdf,
    customizeExportPdf,
  }
}
