<template>
  <div class="h-full flex flex-col gap-2 pb-4 text-white divide-y divide-white/10 min-h-[20rem]">
    <!-- step 1 -->
    <TransitionCollapse>
      <section class="mb-4" v-if="steper >= 1">
        <h3 class="topic my-4">
          選擇牌組
          <span class="subtitle">選擇你的牌組來源</span>
        </h3>

        <div class="grid grid-cols-2 md:grid-cols-4 gap-2">
          <div class="relative h-full w-full">
            <button class="item default-transition active" @click.stop="showMenu = true" :disabled="loading">
              <component :is="nowType.icon" class="icon size-6" />
              <span class="text-sm font-mono">{{ nowType.name }}</span>
            </button>

            <TransitionCollapse>
              <UIDropDown
                ref="dropDown"
                :menus="menus"
                v-if="showMenu"
                :parentSize="48"
                @select="
                  e => {
                    e.action ? e.action(e) : null
                    showMenu = false
                  }
                "
              />
            </TransitionCollapse>
          </div>
          <div
            class="item default-transition"
            :class="{
              active: nowCode && nowDeck
            }"
          >
            <UIInput type="text" placeholder="代碼" v-model="nowCode" :disabled="loading" />

            <button class="rounded-full p-1 -m-1 hover:bg-zinc-500/50 text-white" @click="findDeck" :disabled="loading">
              <PaperAirplaneIcon class="icon size-6" />
            </button>
          </div>

          <div v-if="status" class="col-span-full md:col-span-2 w-full flex items-center gap-2">
            <div
              class="rounded-full p-1"
              :class="{
                'text-white': status === 'loading',
                'bg-green-300/20 text-green-500': status === 'success',
                'bg-red-300/20 text-red-500': status === 'error'
              }"
            >
              <ArrowPathIcon v-if="status === 'loading'" class="flex-none size-5 animate-spin" />
              <CheckIcon v-else-if="status === 'success'" class="flex-none size-5" />
              <XMarkIcon v-else-if="status === 'error'" class="flex-none size-5" />
            </div>

            <span
              class="font-mono truncate"
              :class="{
                'text-white': status === 'loading',
                'text-green-500': status === 'success',
                'text-red-500': status === 'error'
              }"
            >
              {{ status === 'loading' ? 'Loading' : status === 'error' ? '無效代碼' : status === 'success' ? '找到：' + nowDeck?.title : '' }}
            </span>
          </div>
        </div>
        <div class="mt-4 flex items-center gap-2 p-2 rounded-xl bg-amber-500/20 text-white" v-if="nowDeck && !nowDeck.isOwner">
          <ExclamationCircleIcon class="flex-none size-6 stroke-2 text-amber-300" />
          <span>此牌組非您所有，請先詢問作者意願再進行後續動作。作者：{{ nowDeck.author.name }}</span>
        </div>
      </section>
    </TransitionCollapse>

    <!-- step 2 -->
    <TransitionCollapse>
      <section class="mb-4" v-if="steper >= 2">
        <h3 class="topic my-4">
          匯出設定
          <span class="subtitle">選擇你的匯出格式內容</span>
        </h3>

        <h3 class="topic mt-4 mb-2">選擇匯出格式</h3>
        <div class="grid grid-cols-2 md:grid-cols-4 gap-2">
          <button
            v-for="item in exports"
            :key="item.name"
            class="item default-transition"
            :disabled="loaading || item.disabled"
            :class="{ active: nowExport.field === item.field }"
            @click="nowExport = item"
          >
            <component :is="item.icon" class="icon size-6" />
            <span> {{ item.name }} </span>
          </button>
        </div>

        <template v-if="nowExport.field === 'image' || nowExport.field === 'tts'">
          <h3 class="topic mt-4 mb-2">選擇排序</h3>
          <div class="grid grid-cols-3 md:grid-cols-6 gap-2">
            <button
              v-for="item in sorts"
              :key="item.name"
              class="item default-transition"
              :disabled="loading || item.disabled"
              :class="{ active: nowSort.field === item.field }"
              @click="nowSort = item"
            >
              <component :is="item.icon" class="icon size-6" />
              <span> {{ item.name }} </span>
            </button>
          </div>
        </template>

        <template v-if="nowExport.field === 'pdf'">
          <h3 class="topic mt-4 mb-2">選擇紙張大小</h3>
          <div class="grid grid-cols-2 gap-2">
            <button
              v-for="item in pdfSizes"
              :key="item.name"
              class="item default-transition"
              :disabled="loading"
              :class="{ active: nowPdfSize.field === item.field }"
              @click="nowPdfSize = item"
            >
              <component :is="item.icon" class="icon size-6" />
              <span> {{ item.name }} </span>
            </button>
          </div>
        </template>

        <template v-if="nowExport.field === 'txt'">
          <h3 class="topic mt-4 mb-2">進階選項</h3>
          <div class="grid grid-cols-2 gap-2">
            <button
              v-for="item in txtOptions"
              :key="item.name"
              class="item default-transition"
              :disabled="loading"
              :class="{ active: nowTxtOption.includes(item.field) }"
              @click="selectTxtOption(item.field)"
            >
              <component :is="item.icon" class="icon size-6" />
              <div class="flex flex-col items-start">
                <span> {{ item.name }} </span>
                <small> {{ item.description }} </small>
              </div>
            </button>
          </div>
        </template>

        <div class="mt-8 flex justify-center">
          <button class="item md:!w-[20rem] justify-center default-transition" @click="doExport" :disabled="loading">
            <CheckIcon class="icon size-6" />
            <span> 設定完成 </span>
          </button>
        </div>
      </section>
    </TransitionCollapse>

    <!-- step 3 -->
    <TransitionCollapse>
      <section class="mb-4" v-if="steper >= 3">
        <template v-if="nowExport.field === 'image' || nowExport.field === 'tts'">
          <h3 class="topic my-4">
            圖片預覽
            <span class="subtitle">確認沒問題後再點下載</span>
          </h3>

          <div class="mt-8 flex justify-center">
            <button class="item md:!w-[20rem] justify-center default-transition" @click="downloadObject(preview, nowDeck.title)">
              <ArrowDownTrayIcon class="icon size-6" />
              <span> 下載圖片 </span>
            </button>
          </div>

          <div class="my-8 -mx-4 min-h-[30rem] bg-black grid place-content-center">
            <img v-if="preview" :src="preview" alt="" class="object-center object-contain select-none h-[30rem] w-full" />
          </div>
        </template>
        <template v-if="nowExport.field === 'txt'">
          <h3 class="topic my-4">
            內容預覽
            <span class="subtitle">確認沒問題後再點下載</span>
          </h3>

          <div class="mt-8 flex justify-center gap-2">
            <button
              class="item md:!w-[20rem] justify-center default-transition"
              @click="downloadObject(`data:text/plain;charset=utf-8,${encodeURIComponent(preview)}`, `${nowDeck.title}.txt`)"
            >
              <ArrowDownTrayIcon class="icon size-6" />
              <span> 下載文件 </span>
            </button>

            <button class="item md:!w-[20rem] justify-center default-transition" @click="copy(preview)" :disabled="!isSupported">
              <CheckIcon class="icon size-6" v-if="copied && text === preview" />
              <ClipboardDocumentListIcon v-else class="icon size-6" />
              <span> 複製內容 </span>
            </button>
          </div>

          <div class="my-8 -mx-4 h-[30rem] bg-black p-4 overflow-auto">
            <pre v-if="preview" class="select-none w-full text-sm text-zinc-500 whtespace-pre-line">{{ preview }}</pre>
          </div>
        </template>
      </section>
    </TransitionCollapse>
  </div>
</template>

<script setup>
import {
  GlobeAsiaAustraliaIcon,
  BookmarkIcon,
  DocumentTextIcon,
  PaperAirplaneIcon,
  ArrowPathIcon,
  CheckIcon,
  XMarkIcon,
  ExclamationCircleIcon,
  DocumentIcon,
  PhotoIcon,
  TableCellsIcon,
  CubeIcon,
  SquaresPlusIcon,
  CheckCircleIcon,
  ArrowDownTrayIcon,
  ClipboardDocumentListIcon
} from '@heroicons/vue/24/outline'
import { api } from '@/constants/api'

const { query } = defineProps({
  query: {
    type: Object,
    default: {}
  }
})

const { t } = useNuxtApp().$i18n
const { cardWorker } = useWorker()
const { formatCardsArray, groupCards } = useCard()
const { downloadObject } = useDownload()
const { deckExportPdf } = useExportPdf()
const { text, copy, copied, isSupported } = useClipboard()

const steper = ref(1)
const loading = ref(false)

// step 1

const showMenu = ref(false)
const menus = [
  { name: '匯出牌組', field: 'deck', icon: BookmarkIcon, action: e => (nowType.value = e) },
  { name: '匯出文章', field: 'social', icon: GlobeAsiaAustraliaIcon, action: e => (nowType.value = e) }
]
const nowType = ref(menus[0])
const nowCode = ref('')
const nowDeck = ref()

const status = ref('')

const findDeck = () => {
  status.value = 'loading'
  steper.value = 1

  fetchDeckOrSocial(nowType.value.field, nowCode.value)
}

const fetchDeckOrSocial = async (type, code) => {
  const { data } = await useFetch(api[`${type}Code`](code))
  if (data.value) {
    status.value = 'success'
    const deck = data.value
    deck.cards = formatCardsArray(deck.cards)
    nowDeck.value = deck
    // console.log(deck)
    steper.value = 2
  } else {
    status.value = 'error'
  }
}

// step 2

const exports = [
  { name: '牌組 pdf', field: 'pdf', icon: DocumentIcon },
  { name: '卡片 txt', field: 'txt', icon: DocumentTextIcon },
  { name: '匯出圖片', field: 'image', icon: PhotoIcon },
  { name: '匯出 TTS', field: 'tts', icon: CubeIcon }
]
const sorts = [
  { name: t('類型'), field: 'type', unit: '', isClimmax: true, icon: SquaresPlusIcon },
  { name: t('顏色'), field: 'color', unit: '', isClimmax: true, icon: SquaresPlusIcon },
  { name: t('等級'), field: 'level', unit: t('等'), isClimmax: true, icon: SquaresPlusIcon },
  { name: t('費用'), field: 'cost', unit: t('費'), isClimmax: true, icon: SquaresPlusIcon },
  { name: t('稀有度'), field: 'rare', unit: '', isClimmax: false, icon: SquaresPlusIcon },
  { name: t('商品'), field: 'productName', unit: '', isClimmax: false, icon: SquaresPlusIcon }
]
const pdfSizes = [
  { name: 'A4', field: 'A4', icon: DocumentIcon },
  { name: 'A4 (7-11 ibon)', field: 'ibon', icon: DocumentIcon }
]
const txtOptions = [
  { name: '群組卡片', description: '每欄會變成 (ID 數量)', field: 'group', icon: CheckCircleIcon },
  { name: '去除稀有度', description: '將 ID 結尾的稀有度去掉', field: 'rare', icon: CheckCircleIcon },
  { name: 'EN ID 轉換', description: 'ID 都加入 E 開頭 (不會檢查是否有此張牌)', field: 'en', icon: CheckCircleIcon }
]

const nowExport = ref(exports[2])
const nowSort = ref(sorts[0])
const nowPdfSize = ref(pdfSizes[0])
const nowTxtOption = ref([])

const selectTxtOption = field => {
  if (nowTxtOption.value.includes(field)) {
    nowTxtOption.value = nowTxtOption.value.filter(e => e !== field)
  } else {
    nowTxtOption.value.push(field)
  }
}

const doExport = async () => {
  loading.value = true
  steper.value = 2
  const cards = groupCards(nowDeck.value.cards, nowSort.value)
    .filter(e => e.scraper)
    .map(e => {
      e.scraperSrc = useImageProxy(e.scraper, { modifiers: { width: 291, height: 400 } })
      return e
    })
  const deck = JSON.parse(JSON.stringify(nowDeck.value))
  deck.cards = JSON.parse(JSON.stringify(cards))

  if (nowExport.value.field === 'image' || nowExport.value.field === 'tts') {
    cardWorker.value.postMessage({ type: 'exportImage', input: { deck, type: nowExport.value.field } })
  } else if (nowExport.value.field === 'pdf') {
    let blob = await deckExportPdf(deck, nowPdfSize.value.field === 'A4')
    const url = URL.createObjectURL(blob)
    downloadObject(url, `${nowDeck.value.title}.pdf`)
    loading.value = false
  } else if (nowExport.value.field === 'txt') {
    preview.value = useExportTxt(deck, nowTxtOption.value)
    steper.value = 3
    loading.value = false
  }
}

watch(
  () => nowExport.value,
  () => {
    steper.value = 2
  },
  { deep: true }
)

// step 3
const preview = ref()

const dropDown = ref()
onClickOutside(dropDown, () => {
  showMenu.value = false
})

onMounted(() => {
  if (query.open === 'export' && query.code && query.type) {
    nowType.value = menus.find(e => e.field === query.type)
    nowCode.value = query.code
    findDeck()
  }

  cardWorker.value.onmessage = e => {
    if (e.data.type === 'exportImage') {
      // console.log(e.data.data) // blob
      preview.value = URL.createObjectURL(e.data.data)
      steper.value = 3
      loading.value = false
    }
  }
})

onBeforeUnmount(() => {
  cardWorker.value.onmessage = null
  cardWorker.value.terminate()
})
</script>

<style lang="sass" scoped>

.topic
  @apply text-lg font-bold mr-auto text-zinc-200
  .subtitle
    @apply block font-normal text-zinc-400 text-xs

.item
  @apply w-full flex items-center gap-2 p-2 rounded-xl ring-2 ring-zinc-800
  &:not(.active)
    @apply bg-zinc-900/50 text-zinc-400 hover:bg-zinc-800 focus-within:bg-zinc-800 active:bg-zinc-800 disabled:text-zinc-800
  &.active
    @apply bg-gradient-to-r from-blue-500 to-cyan-500 shadow-sky-500/50 text-white

  .icon
    @apply flex-none stroke-2
</style>
