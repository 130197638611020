<template>
  <section class="flex flex-col gap-4">
    <!-- price -->
    <div class="my-4 rounded-xl p-2 text-center">
      <div class="flex items-start justify-center text-amber-500 dark:text-amber-300">
        <CurrencyYenIcon class="size-7" />
        <h3 class="text-6xl font-bold font-mono">{{ showPrice }}</h3>
      </div>
      <p class="mt-4 text-sm text-zinc-500 dark:text-zinc-300">
        {{ $t('cardDetail.at') }} {{ formatTime(props.price.date, 'YYYY-MM-DD') }} {{ $t('cardDetail.priceUpdate') }}
      </p>
    </div>

    <!-- version -->
    <div v-if="relations.length > 0" class="grid grid-cols-3 md:grid-cols-5 place-content-center rounded-2xl bg-zinc-100 dark:bg-zinc-700">
      <button
        v-for="(r, rid) in relations"
        :key="`r-${rid}`"
        class="rounded-xl p-2 min-w-0 flex flex-col items-center"
        :class="{
          'bg-orange-100 dark:bg-orange-500/10': r.type === 'high',
          'bg-blue-100 dark:bg-blue-500/30': r.type === 'low'
        }"
        @click="openCard(r.card)"
      >
        <div
          class="w-full flex items-center justify-center gap-2"
          :class="{
            'text-orange-500 dark:text-orange-200': r.type === 'high',
            'text-blue-500 dark:text-blue-200': r.type === 'low'
          }"
        >
          <span class="hidden md:block font-bold text-center">
            {{ $t(`cardDetail.${r.type}Version`) }}
          </span>
          <span class="font-mono select-none font-bold"> ${{ r.card.price.number }} </span>
        </div>
        <CardCover :src="r.card?.scraper" :rare="r.card?.rare" :isEffect="true" />
        <small class="mt-2 font-mono text-zinc-500 dark:text-zinc-300">{{ r.card.id }}</small>
      </button>
    </div>

    <!-- <div class="flex items-center gap-4">
      <button class="rounded-2xl p-2 bg-green-200 text-green-700 flex items-center gap-1">
        <span> 前往 OOXX 選購 </span>
        <ArrowRightIcon class="ml-auto size-6" />
      </button>
      <button class="rounded-2xl p-2 bg-green-200 text-green-700 flex items-center gap-1">
        <span> 前往 OOXX 選購 </span>
        <ArrowRightIcon class="ml-auto size-6" />
      </button>
      <button class="rounded-2xl p-2 bg-green-200 text-green-700 flex items-center gap-1">
        <span> 前往 OOXX 選購 </span>
        <ArrowRightIcon class="ml-auto size-6" />
      </button>
    </div> -->
  </section>
</template>

<script setup>
import { ArrowRightIcon, CurrencyYenIcon } from '@heroicons/vue/24/outline'
import gsap from 'gsap'
import { Flip } from 'gsap/Flip'
gsap.registerPlugin(Flip)
gsap.config({ force3D: true })

const props = defineProps({
  highVer: {
    type: Array,
    default: []
  },
  lowVer: {
    type: Array,
    default: []
  },
  price: {
    type: Object,
    default: {
      number: 0,
      date: null
    }
  }
})

const { formatTime } = useTool()
const { cardCollections, nowCard } = storeToRefs(useCardStore())

//////////////////////////////////
// card price counter animation

const showPrice = ref(0)
const doShowPrice = () => {
  gsap.to(showPrice, {
    value: props.price.number,
    duration: 2,
    ease: 'power2.inOut',
    onUpdate: () => {
      showPrice.value = Math.round(showPrice.value)
    }
  })
}

onMounted(() => {
  doShowPrice()
})

watch(
  props,
  () => {
    showPrice.value = 0
    doShowPrice()
  },
  { deep: true }
)

//////////////////////////////////
// highVer, lowVer relations
const relations = computed(() => {
  return [...props.highVer.map(e => ({ type: 'high', card: e })), ...props.lowVer.map(e => ({ type: 'low', card: e }))]
    .map(e => {
      e.card = cardCollections.value.find(c => c.id === e.card)
      return e
    })
    .filter(e => e.card)
})

const openCard = card => {
  nowCard.value = card
  document.querySelector('#card-pane').scrollTo(0, 0)
}
</script>
