<template>
  <div class="w-full flex items-center gap-2">
    <div
      class="grow-1 w-full md:w-fit min-w-0 p-2 rounded-full bg-white text-zinc-700 shadow shadow-zinc-900/50 flex items-center gap-1">
      <MagnifyingGlassIcon class="flex-none size-5 stroke-2 text-zinc-700" />
      <input
        class="text-sm p-0 bg-transparent border-none focus:ring-0 placeholder:text-zinc-500 placeholder:font-light w-full min-w-0"
        type="text" :placeholder="$t('headerDeck.searchPlaceholder')" v-model="deckFilters.keyword" />
      <XMarkIcon class="flex-none size-5 stroke-2 cursor-pointer text-zinc-700" @click.stop="deckFilters.keyword = ''" />
    </div>

    <button v-for="(sort, sortid) in sorts" :key="`sort-${sortid}`"
      class="flex-none p-2 rounded-full flex items-center gap-1 font-bold text-zinc-700" :class="{
        'bg-gradient-to-r from-teal-300 to-blue-300 shadow shadow-cyan-900/50': deckFilters.sort[0]?.field === sort.field,
        'bg-white shadow shadow-zinc-900/50': deckFilters.sort[0]?.field !== sort.field,
      }" @click="filterSort(deckFilters, sort)">
      <ArrowUpIcon class="size-5 stroke-2 text-zinc-700 default-transition" :class="{
        'rotate-180': deckFilters.sort[0]?.order === 'asc' && deckFilters.sort[0]?.field === sort.field,
      }" />
      <span class="text-sm">{{ $t(`headerDeck.${sort.i18n}`) }}</span>
    </button>
  </div>
</template>

<script setup>
import { MagnifyingGlassIcon, ArrowUpIcon, XMarkIcon } from '@heroicons/vue/24/outline'
const { deckFilters } = storeToRefs(useDeckStore())
const { filterSort } = useTool()

const sorts = ref([
  { name: '名稱', i18n: 'sortTitle', field: 'title' },
  { name: '日期', i18n: 'sortUpdateAt', field: 'updatedAt' },
])
</script>
