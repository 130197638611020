<template>
  <div class="counter flex items-center gap-x-1">
    <UIButton :disabled="!props.card?.id" class="btn-sm bg-zinc-700 text-white hover:bg-green-400"
      @click.stop="doVibrate('cardDeck', setting.vibrate); doCardDeck('add', props.card)">
      <PlusIcon class="size-6 stroke-2" />
    </UIButton>
    <div class="btn btn-sm" :class="{
      'bg-sky-500 text-white': count > 0 && count <= 4,
      'bg-purple-500 text-white': count > 4,
    }">
      <span class="size-6 stroke-2">
        {{ count }}
      </span>
    </div>
    <UIButton :disabled="!props.card?.id" class="btn-sm bg-zinc-700 text-white hover:bg-red-400"
      @click.stop="doVibrate('cardDeck', setting.vibrate); doCardDeck('minus', props.card)">
      <MinusIcon class="size-6 stroke-2" />
    </UIButton>
  </div>
</template>

<script setup>
import { PlusIcon, MinusIcon } from '@heroicons/vue/24/outline'

const { setting } = storeToRefs(usePreferenceStore())
const { doCardDeck, getCountInCardDeck } = useDeckStore()

const { doVibrate } = useReactiveVibrate()

const props = defineProps({
  card: {
    type: Object,
    default: {},
  },
})

const count = computed(() => getCountInCardDeck(props.card?.id))
</script>