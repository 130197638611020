import { decklogWebsite } from '~/constants/api'

export default () => {
  const { doFetch } = useFetchApi()

  // ajax
  const getAccess = async (_, en) => {
    return await doFetch({
      type: 'deckLogAccess',
      params: { en },
    })
  }

  const postCheck = async ({ title, description, cards, rule }, en) => {
    const body = { title, description, cards, rule }

    return await doFetch({
      type: 'deckLogCheck',
      method: 'POST',
      body,
      params: { en },
    })
  }

  const postPublish = async ({ title, description, cards, tokenId, token, rule }, en) => {
    const body = { title, description, cards, tokenId, token, rule }

    return await doFetch({
      type: 'deckLogPublish',
      method: 'POST',
      body,
      params: { en },
    })
  }

  const putDeckLogCode = async ({ code, deckLog, rule }, en) => {
    const body = { code, deckLog, rule }

    return await doFetch({
      type: 'deckDeckLogCode',
      method: 'PUT',
      body,
      params: { en },
    })
  }
  // validation
  const validateAccess = (res) => {
    if (res && res.tokenId && res.token) return { status: 'success', message: '' }
    return {
      status: 'error',
      message: '獲取權限失敗',
    }
  }

  const validateCheck = (res) => {
    if (res) {
      const msgs = Object.values(res)
        .filter((e) => Array.isArray(e))
        .flat()

      if (msgs.length === 0) return { status: 'success', message: '' }
      else
        return {
          status: 'error',
          message: msgs.join(', '),
        }
    } else
      return {
        status: 'error',
        message: '牌組檢查失敗',
      }
  }

  const validatePublish = (res) => {
    if (res) {
      if (res.status === 'OK')
        return {
          status: 'success',
          message: res.deck_id,
        }
      else
        return {
          status: 'error',
          message: res.status,
        }
    } else {
      return {
        status: 'error',
        message: '牌組發布失敗',
      }
    }
  }

  const validateFinish = (res) => {
    if (res) return { status: 'success', message: res }
    else return { status: 'error', message: '失敗' }
  }

  // callback
  const cbAccess = (data, res) => {
    data.tokenId = res.tokenId
    data.token = res.token
    return data
  }

  const cbPublish = (data, res) => {
    data.deckLog = res.deck_id
    return data
  }

  // finish
  const genUrl = ({ deckLog }, en) => {
    if (deckLog) return decklogWebsite(en, deckLog)
    else return false
  }

  // process
  const process = ref([
    { field: 'access', name: '獲取 DeckLog 新增牌組權限', message: '', status: 'pending', action: getAccess, validate: validateAccess, cb: cbAccess },
    { field: 'check', name: '檢查牌組是否符合規則', message: '', status: 'pending', action: postCheck, validate: validateCheck },
    { field: 'publish', name: '發布牌組至 DeckLog', message: '', status: 'pending', action: postPublish, validate: validatePublish, cb: cbPublish },
    { field: 'upload', name: '上傳成功代碼到貓罐子', message: '', status: 'pending', action: putDeckLogCode },
    { field: 'finish', name: '大功告成！', message: '', status: 'pending', action: genUrl, validate: validateFinish },
  ])

  const resetStatus = () => {
    process.value = process.value.map((e) => {
      e.status = 'pending'
      e.message = ''
      return e
    })
  }

  const prepareData = ({ code, type, title, description, cards, rule }) => ({
    // input
    code,
    isEn: type === 'decklogEn',
    title,
    description,
    cards,
    rule,

    // access
    tokenId: '',
    token: '',

    // finish
    deckLog: '',
  })

  const doProcess = async (input) => {
    resetStatus()
    let data = JSON.parse(JSON.stringify(input))

    for (let i = 0; i < process.value.length; i++) {
      const e = process.value[i]
      e.status = 'loading'

      const res = e.action ? await e.action(data, data.isEn) : true
      const { status, message } = e.validate ? e.validate(res) : res ? { status: 'success', message: '' } : { status: 'error', message: '失敗' }
      e.status = status
      e.message = message

      if (status === 'success') {
        data = e.cb ? e.cb(data, res) : data
      } else {
        return false
      }
    }

    return true
  }

  return {
    process,
    doProcess,
    prepareData,
  }
}
