<template>
  <UIChart :datas="datas" :labels="labels" />
</template>

<script setup>
import { api } from '@/constants/api'

const props = defineProps({
  id: {
    type: String,
    default: '',
  },
})
const id = computed(() => props.id)

const { t } = useNuxtApp().$i18n

const datas = computed(() => [
  {
    label: t('cardDetail.linkPrice'),
    data: dataset.value.map((e) => e.price),
  },
])
const labels = computed(() => dataset.value.map((e) => e.date))

const dataset = ref([])
const { data } = useLazyFetch(api['cardPriceHistory'](), {
  query: { id }, server: false
})

watch(data, () => {
  dataset.value = data.value
})
</script>
