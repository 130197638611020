<template>
  <header>
    <div class="header-container">
      <!-- back -->
      <button
        class="flex-none p-1 rounded-full bg-black/50 text-white default-transition hover:bg-zinc-800/50"
        :disabled="!isBack"
        @click="$router.back()"
        v-if="isShowBackItem"
      >
        <ChevronLeftIcon class="h-6 w-6" />
      </button>

      <!-- forward -->
      <button
        class="hidden md:block flex-none p-1 rounded-full bg-black/50 text-white default-transition hover:bg-zinc-800/50 disabled:opacity-30"
        :disabled="!isForward"
        @click="$router.forward()"
        v-if="isShowBackItem"
      >
        <ChevronRightIcon class="h-6 w-6" />
      </button>

      <!-- content -->
      <div class="w-full min-w-0 text-lg md:text-2xl font-bold text-white">
        <slot />
      </div>

      <!-- notification -->
      <ItemHeaderButton v-if="!matchSize('md')" :name="$t('navbar.notification')" field="notification" @action="isNotificationShow = true">
        <template #icon>
          <BellIcon class="size-6 stroke-2" />
          <span v-if="noReadCount > 0" class="absolute -top-1 -right-1 text-xs rounded-full px-1 bg-red-500 text-white pb-[2px]">
            {{ noReadCount }}
          </span>
        </template>

        <TransitionCollapse>
          <UINotification
            ref="notification"
            v-if="isNotificationShow"
            :position="{ right: '-5rem', top: 0 }"
            @select="e => (isNotificationShow = false)"
            :parentSize="36"
          />
        </TransitionCollapse>
      </ItemHeaderButton>

      <!-- user -->
      <button
        class="hidden md:flex flex-none rounded-full bg-black/50 text-white items-center gap-1 default-transition hover:bg-zinc-800/50"
        @click="$router.push('/user')"
      >
        <div class="p-1 rounded-full">
          <UIUser :user="user" :show="{ head: true, role: true }" bgClass="size-6 flex-none bg-black/70" textClass="m-1 text-zinc-200" />
        </div>
        <span class="text-sm flex-none max-w-[8rem] truncate">{{ user?.name || $t('login.login') }}</span>
        <div class="p-1 rounded-full">
          <ChevronDownIcon class="h-4 w-4 flex-none" />
        </div>
      </button>
    </div>
    <div class="header-bg opacity-0 md:rounded-t-2xl md:overflow-hidden" :style="{ background: props.bgColor }" />
  </header>
</template>

<script setup>
import { ChevronRightIcon, ChevronLeftIcon, ChevronDownIcon, BellIcon } from '@heroicons/vue/24/outline'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

if (process.client) {
  gsap.registerPlugin(ScrollTrigger)
}

const { user, noReadCount } = storeToRefs(useGlobalStore())

const props = defineProps({
  bgColor: {
    type: String,
    default: 'bg-cyan-500'
  }
})

const { matchSize } = useRwd()
const route = useRoute()
const isForward = ref(false)
const isBack = ref(false)

const isShowBackItem = computed(() => {
  // if route includes in ['/', '/series', '/deck'] need to disable back button
  return !['/', '/index', '/series', '/deck', '/social'].includes(route.path)
})

watch(
  () => route.path,
  () => {
    if (history) {
      isForward.value = history.state?.forward
      isBack.value = history.state?.back
    }
  }
)

const isNotificationShow = ref(false)
const notification = ref()
onClickOutside(notification, () => {
  isNotificationShow.value = false
})

onMounted(() => {
  gsap.to('.header-bg', {
    scrollTrigger: {
      trigger: '.content',
      start: 'top top',
      scroller: '.content',
      end: `+=300`,
      scrub: true
    },
    opacity: 1,
    duration: 3
  })
})
</script>

<style lang="sass" scoped>
header
  grid-area: main-view
  @apply relative h-14 md:h-16 min-w-0
  .header-container
    @apply flex items-center gap-2 py-4 px-4 md:px-6 w-full h-full relative z-30
  .header-bg
    @apply absolute z-[20] top-0 left-0 w-full h-full
</style>
