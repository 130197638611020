const downloadObject = (href, name = '') => {
  const link = document.createElement('a')
  link.href = href
  link.download = name.includes('.') ? name : `${name}.png`
  link.click()
  link.remove()
  URL.revokeObjectURL(href)
}

export default () => ({
  downloadObject,
})
