<template>
  <div class="relative z-10 flex items-center gap-4">
    <div
      class="default-transition min-w-0"
      :class="{
        'header-content scroll-hidden': !(!matchSize('lg') && setting.theaterMode)
      }"
    >
      <h2 class="truncate font-bold">
        {{ deckMode === 'edit' ? $t('headerDeck.editNote') : nowDeck?.title || $t('headerDeck.myDeck') }}
      </h2>
    </div>
    <div class="ml-auto flex-none action flex items-center gap-2 h-min default-transition">
      <ItemHeaderButton :name="$t('navbar.share')" field="share" @action="doShare">
        <template #icon>
          <ShareIcon class="size-6 stroke-2" />
        </template>
      </ItemHeaderButton>

      <template v-if="!matchSize('md')">
        <ItemHeaderButton
          v-for="item in menus"
          :key="item.name"
          :name="item.name"
          :field="item.field"
          :class="item.class"
          @action="e => item.action()"
        >
          <template #icon>
            <Component :is="item.icon" class="size-6 stroke-2" />
          </template>
        </ItemHeaderButton>
      </template>

      <ItemHeaderButton
        v-else
        field="detail"
        :class="{
          'bg-zinc-800 text-white': showMenu
        }"
        @action="showMenu = true"
      >
        <template #icon>
          <AdjustmentsHorizontalIcon class="size-6 stroke-2" />
        </template>

        <TransitionCollapse>
          <UIDropDown
            ref="dropDown"
            :position="position"
            :menus="menus"
            v-if="showMenu"
            :parentSize="36"
            @select="
              e => {
                e.action ? e.action() : null
                showMenu = false
              }
            "
          />
        </TransitionCollapse>
      </ItemHeaderButton>
    </div>
  </div>
</template>

<script setup>
import {
  ArrowUpTrayIcon,
  ShareIcon,
  AdjustmentsHorizontalIcon,
  PencilSquareIcon,
  TrashIcon,
  DocumentArrowDownIcon,
  ChatBubbleLeftRightIcon,
  ClipboardDocumentCheckIcon,
  SignalIcon,
  SignalSlashIcon
} from '@heroicons/vue/24/outline'
import { onClickOutside } from '@vueuse/core'

const deckStore = useDeckStore()
const { nowDeck, cardDeckField, deckMode } = storeToRefs(deckStore)
const { deleteDeck, publicDeck, resetCardDeck, resetCardDeckField, doCardDeck, setCardDeck } = deckStore
const { user, shareText, shareUrl } = storeToRefs(useGlobalStore())
const { setting, showPane } = storeToRefs(usePreferenceStore())

const showMenu = ref(false)

const router = useRouter()
const { messageConfirm } = useSwal()
const { share, isSupported } = useShare()
const { matchSize } = useRwd()
const { t } = useNuxtApp().$i18n

const position = computed(() => {
  return matchSize(['xs', 'sm', 'md']) ? { right: 0, top: 0 } : { left: 0, top: 0 }
})

const doShare = async () => {
  shareText.value = `${t('share.text')}${t('share.deck')}：${nowDeck.value?.title}，${t('share.code')}：${nowDeck.value?.code}`
  shareUrl.value = `${location.href}`

  if (isSupported.value) {
    const { value } = await messageConfirm(
      '請注意',
      '牌組代碼只能支援於貓罐子使用，並不能當作官方比賽使用。\n如需上傳代碼，請使用 "上傳至 Decklog" 功能。'
    )
    if (value) {
      share({
        title: nowDeck.value.title,
        text: shareText.value,
        url: shareUrl.value
      })
    }
  } else {
    showPane.value = 'Share'
  }
}

const editDeck = () => {
  // nowDeck to cardDeck
  resetCardDeck()
  resetCardDeckField()

  if (matchSize('md')) {
    nowDeck.value.cards.forEach((card, i) => {
      setTimeout(() => {
        doCardDeck('add', card)
      }, i * 50)
    })
  } else {
    setCardDeck(nowDeck.value.cards)
  }

  cardDeckField.value = {
    title: nowDeck.value.title,
    description: nowDeck.value.description,
    cover: nowDeck.value.cover,
    code: nowDeck.value.code,
    isPublic: nowDeck.value.isPublic || false
  }

  router.push({ path: `/series/${nowDeck.value.series[0] ? nowDeck.value.series[0].id : ''}`, query: { open: 'cardDeck' } })
}

const doPublicDeck = async () => {
  await publicDeck(nowDeck.value?.code, !nowDeck.value?.isPublic)
}

const copyDeck = () => {
  // nowDeck to cardDeck
  resetCardDeck()
  resetCardDeckField()

  if (matchSize('md')) {
    nowDeck.value.cards.forEach((card, i) => {
      setTimeout(() => {
        doCardDeck('add', card)
      }, i * 50)
    })
  } else {
    setCardDeck(nowDeck.value.cards)
  }

  router.push({ path: `/series/${nowDeck.value.series[0] ? nowDeck.value.series[0].id : ''}`, query: { open: 'cardDeck' } })
}

const doDeleteDeck = async () => {
  const { value } = await messageConfirm(t('headerDeck.deleteDeck'), `${t('headerDeck.deleteDeckInfo')} "${nowDeck.value?.title}"`)
  if (value) {
    const res = await deleteDeck(nowDeck.value?.code)
    if (res) {
      router.push('/deck')
    }
  }
}

const menus = computed(() =>
  [
    { name: t('headerDeck.menuEdit'), icon: PencilSquareIcon, action: editDeck, isOwner: true },
    { name: t('headerDeck.menuCopy'), icon: ClipboardDocumentCheckIcon, action: copyDeck },
    {
      name: t('headerDeck.menuExport'),
      icon: DocumentArrowDownIcon,
      action: () => {
        router.push({ query: { open: 'export', type: 'deck', code: nowDeck.value.code } })
        setTimeout(() => (showPane.value = 'export'), 100)
      }
    },
    {
      name: t('headerDeck.menuDeckLog'),
      icon: ArrowUpTrayIcon,
      isOwner: true,
      action: () => {
        router.push({ query: { open: 'deckLogUpload', code: nowDeck.value.code } })
        setTimeout(() => (showPane.value = 'deckLogUpload'), 100)
      }
    },
    {
      name: t('headerDeck.menuPublish'),
      icon: ChatBubbleLeftRightIcon,
      action: () => router.push({ path: '/social/add', query: { code: nowDeck.value.code } }),
      isOwner: true
    },
    {
      name: t('headerDeck.menuPublic'),
      icon: SignalIcon,
      class: 'text-green-700 hover:text-green-400',
      action: doPublicDeck,
      isOwner: true,
      isPublic: false
    },
    {
      name: t('headerDeck.menuPrivate'),
      icon: SignalSlashIcon,
      class: 'text-red-700 hover:text-red-400',
      action: doPublicDeck,
      isOwner: true,
      isPublic: true
    },
    { name: t('headerDeck.menuDelete'), icon: TrashIcon, class: 'text-red-700 hover:text-red-400', action: doDeleteDeck, isOwner: true }
  ]
    .filter(e => (e.isOwner === undefined ? true : nowDeck.value?.isOwner))
    .filter(e =>
      e.isPublic === undefined
        ? true
        : user.value.role !== 'member' || !user.value.publicId
        ? false
        : e.isPublic === (nowDeck.value?.isPublic || false)
    )
)

// event handler
const dropDown = ref()
onClickOutside(dropDown, () => {
  showMenu.value = false
})

onMounted(() => {
  useScrollListener(
    '.content',
    200,
    () => {
      document.querySelector('.header-content')?.classList.remove('scroll-hidden')
      document.querySelector('.header-content')?.classList.add('scroll-show')
    },
    () => {
      if (!(!matchSize('lg') && setting.value.theaterMode)) {
        document.querySelector('.header-content')?.classList.remove('scroll-show')
        document.querySelector('.header-content')?.classList.add('scroll-hidden')
      }
    }
  )
})
</script>

<style lang="sass" scoped>

.scroll-hidden
  @apply opacity-0 -translate-y-10
.scroll-show
  @apply opacity-100 translate-y-0

.action-hidden
  @apply opacity-0 -translate-y-10 w-0

.action-show
  @apply opacity-100 translate-y-0 w-auto
</style>
