<template>
  <section
    class="z-20 absolute max-h-[90vh] w-[22rem] overflow-y-auto flex flex-col rounded-xl bg-zinc-800 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
    :style="{ 'margin-top': parentSize + 'px', ...props.position }"
  >
    <div class="flex flex-col divide-y divide-white/10">
      <h2 class="sticky top-0 bg-zinc-800 px-4 title">{{ $t('notification.title') }} ({{ noReadCount }})</h2>

      <template v-if="notifications.length > 0">
        <a
          class="cursor-pointer p-4 text-white flex items-center gap-2"
          :class="{
            'hover:bg-zinc-500/20': item.isRead,
            'bg-sky-500/10 hover:bg-sky-500/20': !item.isRead
          }"
          v-for="item in notifications"
          :key="item.id"
          @click="doClickNotificaiton(item)"
        >
          <div class="flex-none w-[3rem] h-[3rem] rounded-full bg-zinc-500/50 grid place-content-center">
            <MegaphoneIcon v-if="item.type === 'notification'" class="size-7" />
            <ChatBubbleLeftEllipsisIcon v-else-if="item.type === 'comment'" class="size-7" />
            <BellIcon v-else class="size-7" />
          </div>
          <div class="select-none">
            <h3 class="font-bold line-clamp-2 break-all">{{ $t(`${item.title}`) }}</h3>
            <p class="text-sm text-zinc-400">
              {{ $t(`${item.description}`) }}．<span class="font-mono text-xs">
                {{ formatTime(item.publshAt, 'YYYY-MM-DD') }}
              </span>
            </p>
          </div>

          <button v-if="item.link" class="flex-none text-white ml-auto rounded-full p-1 bg-zinc-500/20 default-transition">
            <ChevronRightIcon class="size-6" />
          </button>
        </a>
      </template>

      <div v-else>
        <UIEmpty />
      </div>
    </div>
  </section>
</template>

<script setup>
import { ChatBubbleLeftEllipsisIcon, MegaphoneIcon, BellIcon, ChevronRightIcon } from '@heroicons/vue/24/outline'
const { formatTime } = useTool()
const router = useRouter()

const emits = defineEmits(['select'])

const props = defineProps({
  position: {
    type: Object,
    default: {
      left: 0,
      top: 0
    }
  },
  parentSize: {
    type: Number,
    default: 0
  }
})

const globalStore = useGlobalStore()
const { readNotification, getNotifications } = globalStore
const { notifications, noReadCount } = storeToRefs(globalStore)
const { showPane } = storeToRefs(usePreferenceStore())

const doClickNotificaiton = item => {
  // console.log(item)
  readNotification(item.id)

  if (item.link) {
    if (item.link.startsWith('http')) {
      window.open(item.link)
    } else {
      showPane.value = null
      router.push(item.link)
    }

    setTimeout(() => {
      emits('select', item)
    }, 100)
  }
}

onMounted(() => {
  getNotifications()
})
</script>
