<template>
  <div class="relative z-10 flex items-center gap-4 ">
    <div class="header-content scroll-hidden default-transition min-w-0">
      <h2 class="truncate text-2xl font-bold">{{ $t('user.title') }}</h2>
    </div>
    <div class="ml-auto flex-none action flex items-center gap-2 h-min default-transition">

      <template v-if="!matchSize('md')">
        <ItemHeaderButton v-for="item in menus" :key="item.name" :name="item.name" :field="item.field" :class="item.class"
          @action="item.action()">
          <template #icon>
            <Component :is="item.icon" class="size-6 stroke-2" />
          </template>
        </ItemHeaderButton>
      </template>

      <ItemHeaderButton v-else field="preference" :class="{
        'bg-zinc-800 text-white': showMenu,
      }" @action="showMenu = true">
        <template #icon>
          <Cog8ToothIcon class="size-6 stroke-2" />
        </template>

        <TransitionCollapse>
          <UIDropDown ref="dropDown" :position="position" :parentSize="36" :menus="menus" v-if="showMenu" @select="e => {
            e.action ? e.action() : null;
            showMenu = false;
          }" />
        </TransitionCollapse>
      </ItemHeaderButton>
    </div>
  </div>
</template>

<script setup>
import { Cog8ToothIcon, ArrowRightOnRectangleIcon, ArrowPathIcon } from '@heroicons/vue/24/outline'
const { user } = storeToRefs(useGlobalStore())
const { matchSize } = useRwd()
const { t } = useNuxtApp().$i18n

const showMenu = ref(false)
const menus = [
  { name: t('user.preference'), icon: Cog8ToothIcon, action: () => clickButton('btnPreference') },
  { name: t('user.reload'), icon: ArrowPathIcon, action: () => clickButton('btnReload') },
  { name: t('user.logout'), icon: ArrowRightOnRectangleIcon, action: () => clickButton('btnLogout'), class: 'text-red-700 hover:text-red-500' },
]
const dropDown = ref()
onClickOutside(dropDown, () => {
  showMenu.value = false
})

const clickButton = (id) => {
  if (document) {
    const tag = document.querySelector(`#${id}`)
    tag.scrollIntoView({ behavior: 'smooth' })
    setTimeout(() => {
      tag.click()
    }, 500)
  }
}

const position = computed(() => {
  return matchSize(['xs', 'sm', 'md']) ? { right: 0, top: 0 } : { left: 0, top: 0 }
})

onMounted(() => {
  useScrollListener(
    '.content', 200,
    () => {
      document.querySelector('.header-content').classList.remove('scroll-hidden')
      document.querySelector('.header-content').classList.add('scroll-show')
    },
    () => {
      document.querySelector('.header-content').classList.remove('scroll-show')
      document.querySelector('.header-content').classList.add('scroll-hidden')
    }
  )
})

</script>
  
<style lang="sass" scoped>
  
  .scroll-hidden
    @apply opacity-0 -translate-y-10
  .scroll-show
    @apply opacity-100 translate-y-0
    
</style>