<template>
  <UINavPane @close="emits('close')" color="#06b6d4">
    <PaneFilter ref="paneFilter" :nowFilters="cardFilters" />

    <template #title>
      <span>{{ $t('paneFilter.title') }}</span>
      <span class="text-sm text-white/80 font-normal">
        {{
          Object.keys(cardFilters)
            .filter(e => !['keyword', 'keywordRule', 'sort'].includes(e))
            .reduce((a, c) => a + cardFilters[c].length, 0)
        }}
        {{ $t('paneFilter.titleFilter') }}、
        {{ cardFilters.sort.length }}
        {{ $t('paneFilter.titleSort') }}、{{ $t('paneFilter.titleKeyword') }}："{{ cardFilters.keyword }}"
      </span>
    </template>

    <template #toolbar>
      <UIButton class="btn-sm bg-black text-white hover:bg-red-500" @click.stop="clearCardFilters()">
        <TrashIcon />
      </UIButton>
      <UIButton class="btn-sm bg-black text-white hover:bg-amber-500" @click.stop="toggleTopicCollapse()">
        <ChevronDownIcon
          class="default-transition"
          :class="{
            'rotate-180': !allTopicCollapse
          }"
        />
      </UIButton>
    </template>

    <template #footer>
      <button
        class="w-full p-2 flex justify-center items-center gap-2 rounded-2xl bg-blue-500"
        :disabled="paneFilter ? paneFilter.isNoChange : true"
        @click="doSetFilter"
      >
        <span class="text-white text-bold"> Apply </span>
      </button>
    </template>
  </UINavPane>
</template>

<script setup>
const emits = defineEmits(['close'])
import { ChevronDownIcon, TrashIcon } from '@heroicons/vue/24/outline'

const cardStore = useCardStore()
const { cardFilters, allTopicCollapse } = storeToRefs(cardStore)
const { toggleTopicCollapse, clearCardFilters, updateCardFilters } = cardStore

// filter pane action
const paneFilter = ref()
const doSetFilter = () => {
  if (paneFilter.value) {
    updateCardFilters(paneFilter.value.cardFilters)
  }
}
</script>
