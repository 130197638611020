<template>
  <Transition name="nav">
    <slot class="will-change-[opacity,transform]" />
  </Transition>
</template>

<style lang="sass">
.nav-enter-active, .nav-leave-active
  transition: opacity,transform 0.3s cubic-bezier(0.55, 0, 0.2, 1)
  overflow: hidden
  position: absolute
  width: 100%
  max-width: calc(510px - 1rem)
  max-height: calc(100% - 1rem)

.nav-enter-from, .nav-leave-to
  opacity: 0
  transform: translate(-50px, 0)

.nav-enter-to, .nav-leave-from
  opacity: 1
  transform: translate(0, 0)
</style>
