<template>
  <footer class="px-4 lg:px-8 pt-20 pb-44 lg:pb-16">
    <nav class="flex flex-col lg:flex-row gap-4">
      <div class="mt-4 grid grid-cols-2 lg:flex lg:flex-col gap-2 lg:min-w-[10rem]">
        <h3 class="col-span-full text-zinc-200">{{ $t('footer.titleIntro') }}</h3>
        <NuxtLink to="/about" class="link">{{ $t('footer.linkAbout') }}</NuxtLink>
        <NuxtLink to="/contact" class="link">{{ $t('footer.linkContact') }}</NuxtLink>
        <a class="link" href="https://ws-tcg.com/" target="_blank">{{ $t('footer.linkWS') }}</a>
        <a class="link" href="https://jasonxddd.notion.site/V2-56de4919639442f4af617ef3cb0b89fc" target="_blank">{{ $t('footer.linkGuide') }}</a>
        <!-- <a class="link" disabled>
          <HandRaisedIcon class="size-4" /> {{ $t('footer.linkPricing') }}
        </a> -->
      </div>
      <div class="mt-4 grid grid-cols-2 lg:flex lg:flex-col gap-2 lg:min-w-[10rem]">
        <h3 class="col-span-full text-zinc-200">{{ $t('footer.titleResources') }}</h3>
        <a class="link" href="https://decklog.bushiroad.com/" target="_blank">{{ $t('footer.linkDeckLog') }}</a>
        <a class="link" href="https://decklog-en.bushiroad.com/" target="_blank">{{ $t('footer.linkDeckLogEN') }}</a>
        <a class="link" href="https://yuyu-tei.jp/" target="_blank">{{ $t('footer.linkPrice') }}</a>
        <a class="link" href="https://ws-tcg.com/rules/" target="_blank">{{ $t('footer.linkRule') }}</a>
        <a class="link" href="https://ws-tcg.com/rules/deck_rule/" target="_blank">{{ $t('footer.linkBan') }}</a>
      </div>
      <div class="mt-4 grid grid-cols-2 lg:flex lg:flex-col gap-2 lg:min-w-[10rem]">
        <h3 class="col-span-full text-zinc-200">{{ $t('footer.titleLink') }}</h3>
        <NuxtLink to="/qa" class="link">{{ $t('footer.linkQA') }}</NuxtLink>
        <NuxtLink to="/user" class="link">{{ $t('footer.linkUser') }}</NuxtLink>
        <NuxtLink :to="{ path: '/user', query: { open: 'setting' } }" class="link">{{ $t('footer.linkPreference') }} </NuxtLink>

        <a class="link cursor-pointer !text-red-700 hover:!text-red-500" @click="logoutFlow">
          {{ $t('footer.linkLogout') }}
        </a>

        <NuxtLink class="link" to="/hero">
          {{ $t('footer.linkHero') }}
        </NuxtLink>
        <!-- <a class="link" disabled>
          <HandRaisedIcon class="size-4" /> {{ $t('footer.linkArticle') }}
        </a> -->
      </div>

      <div class="lg:ml-auto">
        <a
          href="https://hmodhypluj.cashier.ecpay.com.tw/"
          target="_blank"
          class="text-zinc-300 hover:text-cyan-500 text-lg lg:text-xl font-bold flex items-center justify-center gap-2"
        >
          <GiftIcon class="size-6 flex-none" />
          <span>{{ $t('footer.slogan') }}</span>
        </a>
        <div class="mt-2 flex items-center justify-center lg:justify-end gap-2">
          <button class="btn">
            <a href="https://play.google.com/store/apps/details?id=app.bottleneko.twa&pli=1" target="_blank">
              <img src="/images/login/play-store.svg?inline" class="size-6" />
            </a>
          </button>
          <button class="btn">
            <a href="https://apps.apple.com/us/app/%E8%B2%93%E7%BD%90%E5%AD%90/id1604737154" target="_blank">
              <img src="/images/login/apple.svg?inline" class="size-6" />
            </a>
          </button>
          <button class="btn">
            <a href="https://www.facebook.com/BottleNeko" target="_blank">
              <img src="/images/login/facebook.svg?inline" class="size-6" />
            </a>
          </button>
          <button class="btn">
            <a href="https://discord.gg/GKuxTzpB2A" target="_blank">
              <img src="/images/login/discord.svg?inline" class="size-6" />
            </a>
          </button>
          <button class="btn">
            <a href="mailto:bottleneko2021@gmail.com" target="_blank">
              <EnvelopeIcon class="size-6" />
            </a>
          </button>
          <!-- <button class="btn">
            <a href="https://hmodhypluj.cashier.ecpay.com.tw/" target="_blank">
              <GiftIcon class="size-6" />
            </a>
          </button> -->
        </div>
      </div>
    </nav>

    <hr class="my-4 border-zinc-700" />
    <div class="flex flex-col gap-2 lg:flex-row">
      <div class="flex items-center justify-center gap-4">
        <NuxtLink class="link" to="/policy">{{ $t('footer.policy') }}</NuxtLink>
        <NuxtLink class="link" to="/eula">{{ $t('footer.eula') }}</NuxtLink>
        <NuxtLink to="/contact" class="link">{{ $t('footer.b2b') }}</NuxtLink>
      </div>
      <div class="lg:ml-auto text-sm text-zinc-500 flex flex-col lg:flex-row items-center">
        <span>卡片資料來源 Weiβ Schwarz.</span>
        <span>Copyright @ 2023 BottleNeko </span>
      </div>
    </div>
  </footer>
</template>

<script setup>
import { EnvelopeIcon, GiftIcon, HandRaisedIcon } from '@heroicons/vue/24/solid'
const { logout } = useFirebase()
const { user } = storeToRefs(useGlobalStore())
const { decks } = storeToRefs(useDeckStore())

const router = useRouter()

const logoutFlow = async () => {
  const res = await logout()
  if (res) {
    user.value = false
    decks.value = []
    router.push('/login')
  }
}
</script>

<style lang="sass" scoped>
.link
  @apply text-zinc-500 hover:text-zinc-200 flex items-center gap-1
</style>
