<template>
  <div class="w-full flex items-center gap-2 overflow-x-auto">
    <div
      class="grow-1 w-full md:w-fit min-w-0 p-2 rounded-full bg-white text-zinc-700 shadow shadow-zinc-900/50 flex items-center gap-1">
      <MagnifyingGlassIcon class="flex-none size-5 stroke-2 text-zinc-700" />
      <input
        class="text-sm p-0 bg-transparent border-none focus:ring-0 placeholder:text-zinc-500 placeholder:font-light w-full min-w-0"
        type="text" :placeholder="$t('headerQA.searchPlaceholder')" v-model="qaFilters.keyword" />
      <XMarkIcon class="flex-none size-5 stroke-2 cursor-pointer text-zinc-700" @click.stop="qaFilters.keyword = ''" />
    </div>

    <button
      class="flex-none p-2 rounded-full flex items-center gap-1 font-bold text-zinc-700 bg-white shadow shadow-zinc-900/50"
      @click="filterSort(qaFilters, { field: 'id' })">
      <BarsArrowUpIcon class="flex-none size-5 stroke-2 text-zinc-700 default-transition"
        v-if="qaFilters.sort[0]?.order === 'asc'" />
      <BarsArrowDownIcon class="flex-none size-5 stroke-2 text-zinc-700 default-transition" v-else />
    </button>
  </div>
</template>
  
<script setup>
import { MagnifyingGlassIcon, XMarkIcon, BarsArrowDownIcon, BarsArrowUpIcon } from '@heroicons/vue/24/outline'

const { qaFilters } = storeToRefs(useGlobalStore())
const { filterSort } = useTool()

</script>
  