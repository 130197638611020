<template>
  <div class="header-content flex items-center scroll-hidden default-transition">
    <h2 class="truncate mr-auto">{{ nowSeries ? translate(nowSeries, 'name') : $t('headerSeries.series') }}</h2>
    <div class="ml-2 md:ml-4 flex-none action grid place-content-center h-min action-show default-transition">
      <button
        class="md:hidden flex-none p-1 md:p-3 rounded-full bg-cyan-500 text-white shadow shadow-cyan-900/50 relative"
        @click="showPane = 'filter'"
      >
        <FunnelIcon class="size-6" />
        <span
          class="text-xs md:text-normal font-bold text-mono p-2 rounded-full absolute inset-1/2 md:inset-2/3 bg-cyan-700 size-5 grid place-content-center"
        >
          {{ filterCount }}
        </span>
      </button>
    </div>
  </div>
</template>

<script setup>
import { FunnelIcon } from '@heroicons/vue/24/outline'

const { nowSeries } = storeToRefs(useSeriesStore())
const { filterCount } = storeToRefs(useCardStore())
const preferenceStore = usePreferenceStore()
const { translate } = preferenceStore
const { showPane } = storeToRefs(preferenceStore)

onMounted(() => {
  useScrollListener(
    '.content',
    200,
    () => {
      document.querySelector('.header-content').classList.remove('scroll-hidden')
      document.querySelector('.header-content').classList.add('scroll-show')
    },
    () => {
      document.querySelector('.header-content').classList.remove('scroll-show')
      document.querySelector('.header-content').classList.add('scroll-hidden')
    }
  )
})
</script>

<style lang="sass" scoped>

.scroll-hidden
  @apply opacity-0 -translate-y-10
.scroll-show
  @apply opacity-100 translate-y-0

.action-hidden
  @apply opacity-0 -translate-y-10 w-0 overflow-hidden

.action-show
  @apply opacity-100 translate-y-0 w-auto h-auto overflow-visible
</style>
