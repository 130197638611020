<template>
  <section
    class="flex items-center gap-4"
    :class="{
      'w-screen flex-col': orientation === 'portrait',
      'w-[80vw] lg:w-[70vw] 2xl:w-[80rem] flex-row': orientation === 'landscape'
    }"
  >
    <button
      v-if="!matchSize('md')"
      :disabled="!prevCard"
      class="rounded-full p-4 bg-black/50 disabled:bg-black/30 disabled:text-white/20 hover:bg-cyan-500 text-white"
      @click="nowCard = prevCard"
    >
      <ArrowLeftIcon class="size-6 stroke-2" />
    </button>

    <div
      class="flex-none rounded-2xl relative"
      :class="{
        'w-full p-8 xs:px-32 sm:px-48': orientation === 'portrait',
        'p-0 w-4/12': orientation === 'landscape'
      }"
    >
      <div ref="cover" class="w-full" :style="{ transform, rotate, opacity }">
        <CardCover :src="nowCard.scraper" :rare="nowCard.rare" :isEffect="true" />
      </div>
      <button
        v-if="matchSize('md')"
        :disabled="!prevCard"
        class="absolute left-2 bottom-0 bg-black/50 rounded-full p-4 disabled:bg-black/30 disabled:text-white/20 hover:bg-cyan-500 text-white"
        @click="nowCard = prevCard"
      >
        <ArrowLeftIcon class="size-6 stroke-2" />
      </button>

      <button
        v-if="matchSize('md')"
        :disabled="!nextCard"
        class="absolute right-2 bottom-0 bg-black/50 rounded-full p-4 disabled:bg-black/30 disabled:text-white/20 hover:bg-cyan-500 text-white"
        @click="nowCard = nextCard"
      >
        <ArrowRightIcon class="size-6 stroke-2" />
      </button>
    </div>

    <div
      class="grow-1 w-full rounded-t-2xl rounded-b-none md:rounded-2xl bg-zinc-800"
      id="card-pane"
      :class="{
        'h-[80vh]  2xl:h-[50rem] overflow-auto scroll-smooth': orientation === 'landscape'
      }"
    >
      <CardDetail :card="nowCard">
        <template #toolbar>
          <div class="w-full flex items-center gap-2">
            <CardCounter :card="nowCard" />

            <UIButton
              class="ml-auto btn-sm flex-none"
              :class="{
                'bg-gradient-to-r from-purple-500 to-pink-500 text-white shadow shadow-purple-500/50': setting.translation
              }"
              @click="toggleTranslation"
            >
              <LanguageIcon />
            </UIButton>

            <UIButton class="flex-none btn-sm bg-black/70 hover:bg-white hover:text-black text-white" @click="$parent.$emit('close')">
              <XMarkIcon />
            </UIButton>
          </div>
        </template>
      </CardDetail>
    </div>

    <button
      v-if="!matchSize('md')"
      :disabled="!nextCard"
      class="rounded-full p-4 bg-black/50 disabled:bg-black/30 disabled:text-white/20 hover:bg-cyan-500 text-white"
      @click="nowCard = nextCard"
    >
      <ArrowRightIcon class="size-6 stroke-2" />
    </button>
  </section>
</template>

<script setup>
import { LanguageIcon, XMarkIcon, ArrowLeftIcon, ArrowRightIcon } from '@heroicons/vue/24/outline'
const { nowCard, prevCard, nextCard } = storeToRefs(useCardStore())
const preferenceStore = usePreferenceStore()
const { toggleTranslation } = preferenceStore
const { setting } = storeToRefs(preferenceStore)
const { orientation, matchSize } = useRwd()

const cover = ref()
const transform = ref('0')
const rotate = ref('0')
const opacity = ref('1')
const { isSwiping, direction, lengthX, lengthY } = useSwipe(cover, {
  passive: false,
  onSwipe (e) {
    if (lengthX.value < 0) {
      transform.value = `translate(${Math.abs(lengthX.value)}px)`
      rotate.value = `${Math.abs(lengthX.value) / 50}deg`
      opacity.value = `${(500 - Math.abs(lengthX.value)) / 500}`
    } else if (lengthX.value > 0) {
      transform.value = `translate(-${Math.abs(lengthX.value)}px)`
      rotate.value = `-${Math.abs(lengthX.value) / 50}deg`
      opacity.value = `${(500 - Math.abs(lengthX.value)) / 500}`
    } else {
      transform.value = 'translate(0)'
      rotate.value = '0deg'
      opacity.value = '1'
    }
  },
  onSwipeEnd (e, direction) {
    transform.value = 'translate(0)'
    rotate.value = '0deg'
    opacity.value = '1'

    if (direction === 'left') {
      nowCard.value = nextCard.value
    }
    if (direction === 'right') {
      nowCard.value = prevCard.value
    }
  }
})
</script>
