<template>
  <section>
    <div class="text-sm whitespace-pre-line leading-relaxed dark:text-white" v-html="highlight(props.effects[0])" />
    <p v-if="setting.translation && props.effects[1]" class="mt-4 text-xs text-right text-zinc-500 dark:text-zinc-300">
      {{ $t('cardDetail.translate') }} {{ props.effects[1] }} {{ $t('cardDetail.translateProvide') }}
    </p>
  </section>
</template>

<script setup>
const props = defineProps({
  effects: {
    type: Array,
    default: ['', ''], // [efect, author]
  },
})

const { setting } = storeToRefs(usePreferenceStore())
const { highlight } = useCard()
</script>