<template>
  <Transition name="left">
    <slot class="will-change-[opacity,transform]" />
  </Transition>
</template>

<style lang="sass">
.left-enter-active, .left-leave-active
  transition: opacity,transform .3s cubic-bezier(0.55, 0, 0.2, 1)
  overflow: hidden
  position: absolute
  width: 100%

.left-enter-from
  transform: translateX(-100%)
.left-leave-to
  transform: translateX(100%)

.left-enter-to, .left-leave-from
  transform: translateX(0)
</style>
