<template>
  <div class="flex items-center gap-4">
    <h2 class="truncate text-2xl font-bold">{{ $t('headerSocial.editTitle') }}</h2>

    <div class="ml-auto flex items-center gap-4">
      <button class="flex-none p-2 rounded-full flex items-center gap-1 shadow font-bold 
        text-zinc-700 bg-white shadow-zinc-900/50
        hover:text-white hover:bg-amber-500 hovershadow-amber-500/50" @click="isSubmitSocial = true">
        <GlobeAsiaAustraliaIcon class="flex-none size-5 stroke-2" />
        <span class="text-sm">
          {{ $t('headerSocial.submit') }}
        </span>
        <ArrowRightIcon class="flex-none size-5 stroke-2" />
      </button>
    </div>
  </div>
</template>
  

<script setup>
import { GlobeAsiaAustraliaIcon, ArrowRightIcon } from '@heroicons/vue/24/outline'

const { isSubmitSocial } = storeToRefs(useDeckStore())
</script>