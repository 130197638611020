<template>
  <div class="relative z-10 flex items-center gap-4 ">
    <div class="header-content scroll-hidden default-transition min-w-0">
      <h2 class="truncate font-bold">{{ member?.name || $t('member.title') }}</h2>
    </div>
    <div class="ml-auto flex-none action flex items-center gap-2 h-min default-transition">

      <ItemHeaderButton :name="$t('navbar.share')" field="share" @action="doShare">
        <template #icon>
          <ShareIcon class="size-6 stroke-2" />
        </template>
      </ItemHeaderButton>

    </div>
  </div>
</template>

<script setup>
import { ShareIcon } from '@heroicons/vue/24/outline'
const { member, shareText, shareUrl } = storeToRefs(useGlobalStore())
const { showPane } = storeToRefs(usePreferenceStore())

const { share, isSupported } = useShare()

const { t } = useNuxtApp().$i18n


const doShare = () => {
  shareText.value = `${t('share.creator')}：${member.value.name} @${member.value.publicId}`
  shareUrl.value = `${location.href}`

  if (isSupported.value) {
    share({
      title: member.value.name,
      text: shareText.value,
      url: shareUrl.value,
    })
  } else {
    showPane.value = 'Share'
  }
}

onMounted(() => {
  useScrollListener(
    '.content', 200,
    () => {
      document.querySelector('.header-content').classList.remove('scroll-hidden')
      document.querySelector('.header-content').classList.add('scroll-show')
    },
    () => {
      document.querySelector('.header-content').classList.remove('scroll-show')
      document.querySelector('.header-content').classList.add('scroll-hidden')
    }
  )
})

</script>
  
<style lang="sass" scoped>
  
  .scroll-hidden
    @apply opacity-0 -translate-y-10
  .scroll-show
    @apply opacity-100 translate-y-0
  </style>