<template>
  <ClientOnly>
    <TransitionCollapseUp>
      <section
        v-if="deckColors.length > 0"
        class="deck-container default-transition"
        :style="{
          background: matchSize('md') ? mostColor : deckColor
        }"
        @click.stop="clickBuilder"
      >
        <div class="md:hidden flex-none -ml-2 w-14 h-full" @click.stop="nowCard = lastEditCard">
          <div
            class="bg-image h-full w-full !bg-top rounded-lg shadow-xl shadow-black"
            :style="{
              'background-image': `url(${useImageProxy(lastEditCard?.scraper)})`
            }"
          />
        </div>

        <div
          class="w-full h-full md:h-auto gap-2 md:gap-4 flex md:grid md:grid-cols-5 items-center border-t-4 md:border-0"
          :style="{
            'border-image': matchSize('md') ? borderGradient : null
          }"
        >
          <!-- deck title -->
          <div
            class="mr-auto w-full md:mr-0 min-w-0 cursor-pointer group"
            @click.stop="
              () => {
                showPane = 'CardDeck'
                cardDeckPage = 1
              }
            "
          >
            <!-- mobile -->
            <h2 class="md:hidden text-sm font-bold md:text-2xl text-white truncate">
              {{ lastEditMethod === 'add' ? $t('deckBuilder.add') : $t('deckBuilder.delete') }} {{ lastEditCard?.id }}
            </h2>

            <!-- pc -->
            <h2 class="hidden md:block text-normal font-bold md:text-2xl text-zinc-300 group-hover:text-white" @click="updateSwiper">
              {{ cardDeckField.code ? $t('paneCardDeck.paneEditTitle') : $t('deckBuilder.title') }}
            </h2>

            <!-- mobile -->
            <span class="block md:hidden text-zinc-200 text-xs md:text-sm truncate">
              {{ cardDeckField.code ? $t('paneCardDeck.paneEditTitle') + ' ' + cardDeckField.code : $t('deckBuilder.title') }}．{{
                $t('deckBuilder.total')
              }}
              {{ cardDeckCount }}
              {{ $t('deckBuilder.cards') }}
            </span>

            <!-- pc -->
            <div class="hidden md:flex items-center gap-2 text-zinc-200 font-light text-xs md:text-sm">
              {{ cardDeckField.code ? cardDeckField.code + '・' : '' }}{{ $t('deckBuilder.total') }} {{ cardDeckCount }}
              {{ $t('deckBuilder.cards') }}

              <template v-if="editMode !== ''">
                <div :class="{ 'bg-green-500': editMode === 'add', 'bg-red-500': editMode === 'minus' }" class="flex-none h-2 w-2 rounded-full" />

                <span class="flex-none text-sm" :class="{ 'text-green-500': editMode === 'add', 'text-red-500': editMode === 'minus' }">
                  {{ editMode === 'add' ? $t('deckBuilder.adding') : editMode === 'minus' ? $t('deckBuilder.deleting') : '' }}
                </span>
              </template>
            </div>
          </div>

          <!-- deck cards: center -->
          <div class="col-span-3 relative hidden md:flex mx-auto w-full h-full items-center gap-2">
            <button
              class="swiper-prev flex-none p-1 rounded-full bg-black/70 hover:bg-white/10 disabled:bg-white/5 disabled:text-white/10 text-white"
            >
              <ChevronLeftIcon class="h-6 w-6" />
            </button>

            <ClientOnly>
              <Swiper
                @swiper="getSwiper"
                @afterInit="updateSwiper"
                class="min-w-[20rem] w-full h-full translate-y-[25%] hover:translate-y-[-25%] default-transition mask"
                :modules="[SwiperEffectCoverflow, SwiperNavigation, SwiperKeyboard, SwiperMousewheel]"
                :navigation="{ nextEl: '.swiper-next', prevEl: '.swiper-prev' }"
                :coverflowEffect="{ rotate: -7, stretch: 0, depth: 100, slideShadows: false }"
                :keyboard="true"
                :mousewheel="true"
                :slides-per-view="slidePerView"
                effect="coverflow"
                :centerInsufficientSlides="false"
                :centeredSlides="true"
                :grabCursor="true"
                :spaceBetween="-10"
              >
                <SwiperSlide v-for="(deck, deckid) in decks" :key="`deck-${deckid}`">
                  <!-- cover -->
                  <div class="mt-6 h-full w-full relative max-w-[9rem]" @click.stop="selectCard(deck.card)">
                    <CardCover
                      class="rounded-card shadow-[0_-5px_15px_rgba(0,0,0,.5)]"
                      v-if="deck.card.scraper"
                      :src="deck.card.scraper"
                      :rare="deck.card.rare"
                      :isEffect="true"
                    />

                    <button
                      class="absolute top-0 right-0 flex-none rounded-full p-1 shadow-xl"
                      :class="{
                        'bg-cyan-500': editMode === '',
                        'bg-green-500': editMode === 'add',
                        'bg-red-500': editMode === 'minus'
                      }"
                    >
                      <span class="h-5 w-5 text-mono font-bold text-white grid content-center text-center">
                        {{ deck.count }}
                      </span>
                    </button>
                  </div>
                </SwiperSlide>
              </Swiper>
            </ClientOnly>

            <button
              class="swiper-next ml-auto flex-none p-1 rounded-full bg-black/70 hover:bg-white/10 disabled:bg-white/5 disabled:text-white/10 text-white"
            >
              <ChevronRightIcon class="h-6 w-6" />
            </button>
          </div>

          <div
            class="w-full flex justify-end items-center gap-2 cursor-pointer"
            @click.stop="
              () => {
                showPane = 'CardDeck'
                cardDeckPage = 1
              }
            "
          >
            <!-- add button -->
            <button
              class="hidden md:block flex-none p-1 rounded-full text-white"
              :class="{
                'bg-green-400': editMode === 'add',
                'hover:bg-zinc-700': editMode !== 'add'
              }"
              @click.stop="editMode = editMode === 'add' ? '' : 'add'"
            >
              <PlusIcon class="h-6 w-6" />
            </button>

            <!-- delete button -->
            <button
              class="hidden md:block flex-none p-1 rounded-full text-white"
              :class="{
                'bg-red-400': editMode === 'minus',
                'hover:bg-zinc-700': editMode !== 'minus'
              }"
              @click.stop="editMode = editMode === 'minus' ? '' : 'minus'"
            >
              <MinusIcon class="h-6 w-6" />
            </button>

            <!-- clear button -->
            <button class="hidden md:block flex-none p-1 rounded-full text-white hover:bg-zinc-700" @click.stop="doClear">
              <TrashIcon class="h-6 w-6" />
            </button>

            <!-- show pane -->
            <button
              class="flex-none p-1 rounded-full hover:bg-cyan-400 text-white"
              @click.stop="
                () => {
                  showPane = 'CardDeck'
                  cardDeckPage = 1
                }
              "
            >
              <DocumentArrowUpIcon class="h-6 w-6" />
            </button>

            <!-- show total price -->
            <div class="px-2 py-1 bg-amber-400/80 rounded-full text-white text-sm default-transition hover:bg-amber-500 flex items-center gap-1">
              <CurrencyYenIcon class="size-6 flex-none" />
              <span class="font-mono">{{ cardDeckPrice }}</span>
              <span>¥</span>
            </div>
          </div>

          <!-- show chart -->
          <!-- <button class="flex-none p-1 rounded-full hover:bg-cyan-400 text-white">
            <ChartPieIcon class="h-6 w-6" />
          </button> -->
        </div>
      </section>
    </TransitionCollapseUp>

    <TransitionCollapseUp>
      <UIPane v-if="showPane === 'CardDeck'" @close="showPane = null">
        <PaneCardDeck
          :page="cardDeckPage"
          ref="cardDeckPane"
          @success="
            code => {
              showPane = null
              $router.push(`/deck/${code}`)
            }
          "
        />

        <template #title>
          <div class="flex items-center gap-2">
            <p class="text-lg font-bold">{{ $t('paneCardDeck.titleSelect') }} {{ cardDeckCount }} {{ $t('paneCardDeck.titleUnit') }}</p>
            <span v-if="cardDeckField.code !== ''" class="px-2 text-sm text-white bg-cyan-500 rounded-lg">
              {{ $t('deckBuilder.editing') }}
            </span>
          </div>
          <div class="flex items-center gap-2">
            <span class="text-sm text-zinc-400">
              {{ $t('paneCardDeck.totalPrice') }}
              <span class="font-mono"> ¥{{ cardDeckPrice }} </span>
            </span>

            <template v-if="editMode !== ''">
              <div :class="{ 'bg-green-500': editMode === 'add', 'bg-red-500': editMode === 'minus' }" class="flex-none h-2 w-2 rounded-full" />

              <span class="flex-none text-sm" :class="{ 'text-green-500': editMode === 'add', 'text-red-500': editMode === 'minus' }">
                {{ editMode === 'add' ? $t('deckBuilder.adding') : editMode === 'minus' ? $t('deckBuilder.deleting') : '' }}
              </span>
            </template>
          </div>
        </template>

        <template #toolbar>
          <UIButton class="flex-none btn-sm bg-black text-white hover:bg-red-500" @click="doClear">
            <TrashIcon />
          </UIButton>

          <!-- <UIButton class="flex-none btn-sm bg-black text-white hover:bg-amber-500" @click="toggleGroupCollapse()">
            <ChevronDownIcon class="default-transition" :class="{
              'rotate-180': allGroupOpen,
            }" />
          </UIButton> -->
        </template>

        <template #footer>
          <div class="p-4 flex items-center gap-2">
            <!-- prev -->
            <button
              v-if="cardDeckPage > 1"
              @click="cardDeckPage -= 1"
              class="flex-none flex items-center gap-1 p-2 rounded-xl text-cyan-600 hover:text-cyan-500"
            >
              <ArrowLeftIcon class="size-5" />
              <span class="text-sm font-bold"> {{ $t('paneCardDeck.btnPrev') }} </span>
            </button>

            <!-- next -->
            <button
              v-if="cardDeckPage <= 1"
              @click="cardDeckPage += 1"
              class="w-full flex justify-center items-center gap-1 p-2 rounded-xl bg-amber-600 hover:bg-amber-500 shadow shadow-amber-500/50 text-white"
            >
              <span class="font-bold"> {{ $t('paneCardDeck.btnNext') }} </span>
              <ArrowRightIcon class="size-5" />
            </button>

            <!-- finish -->
            <button
              v-if="cardDeckPage === 2"
              class="w-full flex justify-center items-center gap-1 p-2 rounded-xl bg-amber-600 hover:bg-amber-500 shadow shadow-amber-500/50 text-white"
              @click="doAddDeck"
            >
              <span class="font-bold"> {{ $t('paneCardDeck.btnFinish') }} </span>
              <PaperAirplaneIcon class="size-5" />
            </button>
          </div>
        </template>
      </UIPane>
    </TransitionCollapseUp>
  </ClientOnly>
</template>

<script setup>
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  PlusIcon,
  MinusIcon,
  DocumentArrowUpIcon,
  CurrencyYenIcon,
  PaperAirplaneIcon,
  ArrowRightIcon,
  ArrowLeftIcon,
  TrashIcon
} from '@heroicons/vue/24/outline'
import * as _ from 'lodash'
import { cardColorsEN } from '@/constants/colors'

const deckStore = useDeckStore()
const { doCardDeck, resetCardDeck, resetCardDeckField } = deckStore
const { cardDecks, cardDeckPrice, cardDeckCount, lastEditCard, lastEditMethod, editMode, cardDeckField } = storeToRefs(deckStore)
const { nowCard } = storeToRefs(useCardStore())
const { showPane, setting } = storeToRefs(usePreferenceStore())

const { matchSize, width } = useRwd()
const { messageConfirm } = useSwal()
const { t } = useNuxtApp().$i18n
const { doVibrate } = useReactiveVibrate()

const decks = computed(() => Array.from(cardDecks.value).map(e => e[1]))

const cardDeckPage = ref(1)

const clickBuilder = () => {
  if (matchSize('md')) {
    showPane.value = 'CardDeck'
    cardDeckPage.value = 1
  }
}

const slidePerView = computed(() => {
  return Math.floor(((width.value / 5) * 3) /*grid*/ / 145 /*card size*/) + 0.5
})

// deck click

const selectCard = card => {
  if (editMode.value) {
    doVibrate('cardDeck', setting.value.vibrate)
    doCardDeck(editMode.value, card)
  } else {
    nowCard.value = card
  }
}

// action: doAddDeck
const cardDeckPane = ref()
const doAddDeck = () => {
  if (cardDeckPane.value) cardDeckPane.value.addDeck()
}

// border color
const deckColors = computed(() =>
  Array.from(cardDecks.value, ([k, v]) => [v.count, v.card.color])
    .reduce((a, c) => {
      const ids = a.map(e => e[0]).indexOf(c[1])
      if (ids === -1) a.push([c[1], c[0]])
      else a[ids][1] += c[0]
      return a
    }, [])
    .sort((a, b) => b[1] - a[1])
)

const borderGradient = computed(() => {
  const cs = []
  let tmp = 0
  deckColors.value.forEach(e => {
    cs.push(`${cardColorsEN[e[0]]} ${(tmp * 100) / cardDeckCount.value}%`)
    tmp += e[1]
    cs.push(`${cardColorsEN[e[0]]} ${(tmp * 100) / cardDeckCount.value - 5}%`)
  })
  return `linear-gradient(to right, ${cs.join(', ')}) 5`
})

const mostColor = computed(() => {
  return `${cardColorsEN[deckColors.value[0] ? deckColors.value[0][0] : 'default']}55`
})

const deckColor = computed(() => {
  // first deckColors gradient to right
  const baseColor = '#121212'
  const cs = []
  let tmp = 0
  deckColors.value.forEach(e => {
    cs.push(`${cardColorsEN[e[0]]}40 ${(tmp * 100) / cardDeckCount.value}%`)
    tmp += e[1]
    cs.push(`${cardColorsEN[e[0]]}40 ${(tmp * 100) / cardDeckCount.value - 5}%`)
  })

  let gradeint = `linear-gradient(90deg, ${cs.join(', ')})`

  // add mask top top transparent
  gradeint = `linear-gradient(to bottom, transparent, ${baseColor}55 5%, ${baseColor}b5 20%, ${baseColor} 60%), ${gradeint}`

  return gradeint
})

// action: doClear
const doClear = async () => {
  const { value } = await messageConfirm(t('deckBuilder.clearDeck'), t('deckBuilder.clearDeckInfo'))
  if (value) {
    resetCardDeck()
    resetCardDeckField()
    showPane.value = null
  }
}

// swiper
const swiper = ref()

const getSwiper = instance => {
  swiper.value = instance
}
const updateSwiper = () => {
  setTimeout(() => {
    if (swiper.value) swiper.value.update()
  }, 1000)
}
</script>

<style lang="sass" scoped>
.deck-container
  grid-area: deck-builder
  @apply flex items-center justify-center gap-2 md:gap-4 z-[30] w-full h-14 md:h-24 px-2 md:px-4 rounded-lg md:rounded-none backdrop-blur-xl md:bg-zinc-900 md:border-t md:border-zinc-800 select-none

.swiper-slide
  @apply saturate-[.5] brightness-[.5] mt-4 duration-100 will-change-[filter] transition-all
  &.swiper-slide-prev,&.swiper-slide-next
    @apply saturate-[.8] brightness-[.8] mt-2
  &.swiper-slide-active
    @apply saturate-100 brightness-100 mt-0

.mask
  mask-image: linear-gradient(to right, transparent, black 20%, black 80%, transparent)
</style>
