<template>
  <section class="content-container">
    <!-- logo -->
    <NuxtLink @click="scrollTop" to="/" class="flex items-center gap-2">
      <img src="/icon.png" class="rounded-2xl shadow h-[2.5rem]" />
      <LogoTitle class="h-[1.5rem] default-transition fill-white" />
      <h1 class="hidden">BottleNeko</h1>
    </NuxtLink>

    <!-- navigation -->
    <div class="mt-4">
      <NuxtLink
        @click="scrollTop"
        v-for="(nav, navid) in navs"
        :key="`nav-${navid}`"
        :to="nav.link"
        class="h-10 flex gap-4 items-center"
        :class="{ 'text-white': isNowNav(nav.link), 'text-zinc-400 hover:text-white': !isNowNav(nav.link) }"
      >
        <component :is="isNowNav(nav.link) ? nav.active : nav.icon" class="flex-none w-7 h-7" />
        <span class="text-sm font-bold truncate">{{ $t('navbar.' + nav.name) }}</span>
      </NuxtLink>
    </div>

    <ClientOnly>
      <!-- tranlate -->
      <div class="mt-4 -ml-2">
        <button
          class="w-full h-10 pl-2 flex gap-4 items-center rounded-lg"
          :class="{
            'text-white font-bold bg-gradient-to-r from-purple-500 to-pink-500': setting.translation,
            'text-zinc-400 hover:text-white': !setting.translation
          }"
          @click="toggleTranslation"
        >
          <component :is="setting.translation ? translateIcon.active : translateIcon.icon" class="flex-none w-7 h-7" />
          <span class="text-sm font-bold truncate">{{ $t('navbar.translate') }}</span>
        </button>
      </div>

      <hr class="my-2 border-zinc-700" />

      <!-- my deck -->
      <div class="overflow-y-auto -mr-6">
        <template v-if="decks.length > 0">
          <div class="py-1 pr-4 flex" v-for="deck in decks" :key="deck.code">
            <NuxtLink
              @click="scrollTop"
              :to="'/deck/' + deck.code"
              class="text-sm truncate w-full flex items-center gap-2"
              :class="{
                'text-white': route.path === '/deck/' + deck.code,
                'text-zinc-400 hover:text-white': route.path !== '/deck/' + deck.code
              }"
            >
              <OutlineBookmarkIcon class="flex-none size-4" />
              {{ deck.title }}
            </NuxtLink>
          </div>
        </template>
        <template v-else>
          <div class="py-1 pr-4 flex">
            <span class="text-sm truncate w-full text-zinc-400">{{ $t('empty') }}</span>
          </div>
        </template>
      </div>

      <div class="-mx-2 my-2">
        <UIPWAInstall type="small" />
      </div>
    </ClientOnly>
  </section>
</template>

<script setup>
import UrlPattern from 'url-pattern'
import LogoTitle from '~~/assets/svg/logo-zh.svg'
import {
  HomeIcon as OutlineHomeIcon,
  Square2StackIcon as OutlineSquare2StackIcon,
  BookmarkIcon as OutlineBookmarkIcon,
  GlobeAsiaAustraliaIcon as OutlineGlobeAsiaAustraliaIcon,
  CubeIcon as OutlineCubeIcon,
  AtSymbolIcon as OutlineAtSymbolIcon,
  GlobeAltIcon as OutlineGlobeAltIcon
} from '@heroicons/vue/24/outline'
import {
  HomeIcon as SolidHomeIcon,
  Square2StackIcon as SolidSquare2StackIcon,
  BookmarkIcon as SolidBookmarkIcon,
  GlobeAsiaAustraliaIcon as SolidGlobeAsiaAustraliaIcon,
  CubeIcon as SolidCubeIcon,
  AtSymbolIcon as SolidAtSymbolIcon,
  GlobeAltIcon as SolidGlobeAltIcon
} from '@heroicons/vue/24/solid'

const preferenceStore = usePreferenceStore()
const { setting } = storeToRefs(preferenceStore)
const { toggleTranslation } = preferenceStore
const { decks } = storeToRefs(useDeckStore())

const navs = ref([
  { link: '/', title: '首頁', name: 'home', icon: OutlineHomeIcon, active: SolidHomeIcon },
  { link: '/series', title: '卡表', name: 'series', icon: OutlineSquare2StackIcon, active: SolidSquare2StackIcon },
  { link: '/deck', title: '我的牌組', name: 'deck', icon: OutlineBookmarkIcon, active: SolidBookmarkIcon },
  { link: '/social', title: '社群', name: 'social', icon: OutlineGlobeAsiaAustraliaIcon, active: SolidGlobeAsiaAustraliaIcon },
  { link: '/workshop', title: '工作坊', name: 'workshop', icon: OutlineCubeIcon, active: SolidCubeIcon }
  // { link: '/about', title: '關於我', name: 'about', icon: OutlineAtSymbolIcon ,active: SolidAtSymbolIcon },
])

const translateIcon = ref({ icon: OutlineGlobeAltIcon, active: SolidGlobeAltIcon })

const route = useRoute()
const isNowNav = link => {
  if (link === '/') return route.path === '/'
  else return new UrlPattern(`${link}(/:id)`).match(route.path)
}
const scrollTop = () => {
  document.querySelector('#main').scrollTo(0, 0)
}
</script>

<style lang="sass" scoped>

.content-container
  @apply flex-1 w-full flex flex-col min-h-0 pt-4 px-4 select-none
</style>
