export default () => {
  // ajax
  const postDetection = async (image, type = 'all') => {
    const api = (type) => `https://jasonxddd.me:5000/api/detect/image/${type}`
    const method = 'POST'
    const params = {}

    // body is formdata, image is file
    const body = new FormData()
    body.append('image', image)

    return await $fetch(api(type), { method, params, body }).catch(async (e) => {
      return false
    })
  }

  return {
    postDetection,
  }
}
