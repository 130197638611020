<template>
  <div class="w-full flex items-center gap-2 overflow-x-auto">
    <div
      class="grow-1 w-full md:w-fit min-w-0 p-2 rounded-full bg-white text-zinc-700 shadow shadow-zinc-900/50 flex items-center gap-1">
      <MagnifyingGlassIcon class="flex-none size-5 stroke-2 text-zinc-700" />
      <input
        class="text-sm p-0 bg-transparent border-none focus:ring-0 placeholder:text-zinc-500 placeholder:font-light w-full min-w-0"
        type="text" :placeholder="$t('headerSeries.searchPlaceholder')" v-model="seriesFilters.keyword" />
      <XMarkIcon class="flex-none size-5 stroke-2 cursor-pointer text-zinc-700"
        @click.stop="seriesFilters.keyword = ''" />
    </div>

    <button v-for="(sort, sortid) in sorts" :key="`sort-${sortid}`"
      class="flex-none p-2 rounded-full flex items-center gap-1 font-bold text-zinc-700" :class="{
        'bg-gradient-to-r from-teal-300 to-blue-300 shadow shadow-cyan-900/50': seriesFilters.sort[0]?.field === sort.field,
        'bg-white shadow shadow-zinc-900/50': seriesFilters.sort[0]?.field !== sort.field,
      }" @click="filterSort(seriesFilters, sort)">
      <ArrowUpIcon class="flex-none size-5 stroke-2 text-zinc-700 default-transition" :class="{
        'rotate-180': seriesFilters.sort[0]?.order === 'asc' && seriesFilters.sort[0]?.field === sort.field,
      }" />
      <span class="text-sm">{{ $t(`headerSeries.${sort.i18n}`) }}</span>
    </button>
  </div>
</template>

<script setup>
import { MagnifyingGlassIcon, ArrowUpIcon, XMarkIcon } from '@heroicons/vue/24/outline'

const seriesStore = useSeriesStore()
const { seriesFilters } = storeToRefs(seriesStore)
const { filterSort } = useTool()

const sorts = ref([
  { name: '系列', i18n: 'sortName', field: 'name' },
  { name: '日期', i18n: 'sortSell', field: 'sell' },
])

</script>
