
<template>
  <section class="flex flex-col gap-4">
    <template v-for="(qa, qaid) in qas" :key="`qa-${qaid}`">
      <!-- Q -->
      <div class="flex items-end gap-2">
        <!-- content -->
        <div class="bg-gradient-to-tr from-emerald-500 to-green-300 p-2 rounded-2xl max-w-[80%]">
          <div class="whitespace-pre-line leading-relaxed text-sm" v-html="highlight(translate(qa, 'q'))" />
          <small class="mt-4 block text-zinc-500 text-right" v-if="setting.translation && translate(qa, 'author')">
            {{ $t('cardDetail.translate') }} {{ translate(qa, 'author') }} {{ $t('cardDetail.translateProvide') }}
          </small>
        </div>
        <!-- info -->
        <div class="flex flex-col">
          <span class="font-bold text-zinc-300">Q.{{ qa.id }}</span>
          <span class="text-xs font-mono whitespace-nowrap text-zinc-500">{{ qa.date }}</span>
        </div>
      </div>

      <!-- A -->
      <div class="flex items-end flex-row-reverse gap-2">
        <!-- content -->
        <div class="bg-gradient-to-bl from-white to-neutral-400 p-2 rounded-2xl max-w-[80%]">
          <div class="whitespace-pre-line leading-relaxed text-sm" v-html="highlight(translate(qa, 'a'))" />
          <small class="mt-4 block text-zinc-500 text-right" v-if="setting.translation && translate(qa, 'author')">
            {{ $t('cardDetail.translate') }} {{ translate(qa, 'author') }} {{ $t('cardDetail.translateProvide') }}
          </small>
        </div>
        <!-- info -->
        <div class="flex flex-col text-right">
          <span class="font-bold text-zinc-300">A.{{ qa.id }}</span>
          <span class="text-xs font-mono whitespace-nowrap text-zinc-500">{{ qa.date }}</span>
        </div>
      </div>
      <hr class="my-2 -mx-4 border-zinc-700" v-if="qaid !== qas.length - 1" />
    </template>

    <div v-if="qas.length === 0" class="h-[10rem] grid place-content-center rounded-2xl text-white bg-black/20">
      <div class="flex items-center">
        <HandRaisedIcon class="size-8" />
        <span>{{ $t('empty') }}</span>
      </div>
    </div>
  </section>
</template>

<script setup>
import { HandRaisedIcon } from '@heroicons/vue/24/outline'
import { api } from '@/constants/api'

const props = defineProps({
  id: {
    type: String,
    default: '',
  },
})
const id = computed(() => props.id)

const preferenceStore = usePreferenceStore()
const { translate } = preferenceStore
const { setting } = storeToRefs(preferenceStore)
const { highlight } = useCard()

const qas = ref([])
const { data } = useLazyFetch(api['qa'](), {
  query: { id }, server: false
})

watch(data, () => {
  qas.value = data.value
})
</script>