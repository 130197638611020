<template>
  <div class="relative z-10 flex items-center gap-4 ">
    <div class="default-transition min-w-0 flex items-center gap-2" :class="{
      'header-content scroll-hidden': !(!matchSize('lg') && setting.theaterMode)
    }">
      <img src="/images/decklog.png" class="size-6" alt="" />
      <h2 class="truncate font-bold">{{ nowDeck?.title || $t('headerDeck.myDeck') }}</h2>
    </div>


    <div class="ml-auto flex-none action flex items-center gap-2 h-min default-transition">

      <ItemHeaderButton :name="$t('headerSocial.menuCopy')" field="copy" @action="copyDeck">
        <template #icon>
          <ClipboardDocumentCheckIcon class="size-6 stroke-2" />
        </template>
      </ItemHeaderButton>

    </div>
  </div>
</template>

<script setup>
import { ClipboardDocumentCheckIcon } from '@heroicons/vue/24/outline'

const deckStore = useDeckStore()
const { nowDeck } = storeToRefs(deckStore)
const { resetCardDeck, resetCardDeckField, doCardDeck } = deckStore
const { setting } = storeToRefs(usePreferenceStore())
const { matchSize } = useRwd()

const copyDeck = () => {
  // nowDeck to cardDeck
  resetCardDeck()
  resetCardDeckField()

  nowDeck.value.cards.forEach((card, i) => {
    setTimeout(() => {
      doCardDeck('add', card)
    }, i * 50)
  })
}

onMounted(() => {
  useScrollListener(
    '.content', 200,
    () => {
      document.querySelector('.header-content')?.classList.remove('scroll-hidden')
      document.querySelector('.header-content')?.classList.add('scroll-show')
    },
    () => {
      if (!(!matchSize('lg') && setting.value.theaterMode)) {
        document.querySelector('.header-content')?.classList.remove('scroll-show')
        document.querySelector('.header-content')?.classList.add('scroll-hidden')
      }
    }
  )
})

</script>


<style lang="sass" scoped>

  .scroll-hidden
    @apply opacity-0 -translate-y-10
  .scroll-show
    @apply opacity-100 translate-y-0
  
  .action-hidden
    @apply opacity-0 -translate-y-10 w-0
  
  .action-show
    @apply opacity-100 translate-y-0 w-auto
    
</style>