<template>
  <Body class="dark">
    <div class="bg-black overflow-hidden root-container">
      <ClientOnly>
        <Header class="md:mt-2 md:mr-2" :bgColor="nowHeader.color">
          <component :is="nowHeader.component" />
        </Header>

        <Tabbar v-if="matchSize('md')" />
        <template v-else>
          <NavContainer :width="showNavPane ? 510 : 270">
            <TransitionNav>
              <NavPaneCardDeck v-if="showNavPane === 'cardDeck'" @close="showNavPane = ''" />
              <NavPaneFilter v-else-if="showNavPane === 'filter'" @close="showNavPane = ''" />
              <Navbar v-else />
            </TransitionNav>
          </NavContainer>
          <!-- <DeckBuilder /> -->
        </template>
      </ClientOnly>

      <main class="content-container bg-base md:my-2 md:mr-2 md:rounded-2xl">
        <div id="main" class="content scroll-smooth">
          <slot />
          <Footer v-if="isShowFooter" />
        </div>
      </main>
    </div>

    <TransitionCollapseUp>
      <UIPane v-if="showPane === 'Share'" @close="showPane = null">
        <PaneShare :text="shareText" :url="shareUrl" />
        <template #title>
          <div class="flex items-center gap-2">
            <p class="text-lg font-bold">
              {{ $t('paneShare.title') }}
            </p>
          </div>
        </template>
      </UIPane>

      <UIPane v-else-if="showPane === 'heroCode'" @close="showPane = null">
        <PaneHeroCode :query="route.query" />
        <template #title>
          <p class="text-lg font-bold">{{ $t('user.heroCode') }}</p>
        </template>
      </UIPane>
    </TransitionCollapseUp>

    <ClientOnly>
      <component :is="cardPaneTransition">
        <template v-for="tool in toolPaneList">
          <UIPane
            v-if="showPane === tool.field"
            :key="tool.field"
            @close="showPane = null"
            :confirm="!matchSize('md')"
            :blank="tool.field === 'cardDetection'"
            :large="tool.field === 'buildCard'"
          >
            <component :is="toolContentList[tool.content]" :query="route.query" />
            <template #title>
              {{ tool.title }}
            </template>
          </UIPane>
        </template>
      </component>
    </ClientOnly>

    <component :is="cardPaneTransition">
      <UIPane blank v-if="nowCard" @close="nowCard = null">
        <CardPane />
      </UIPane>
    </component>
  </Body>
</template>

<script setup>
import UrlPattern from 'url-pattern'

const { nowSeriesColor } = storeToRefs(useSeriesStore())
const { nowCard } = storeToRefs(useCardStore())
const { nowDeckColor, deckMode } = storeToRefs(useDeckStore())

const { shareText, shareUrl, tools } = storeToRefs(useGlobalStore())
const { showPane, showNavPane } = storeToRefs(usePreferenceStore())

const route = useRoute()
const { matchSize, width: windowWidth } = useRwd()
const token = useCookie('token')

const isShowFooter = computed(() => !new UrlPattern('/login').match(route.path))
const cardPaneTransition = computed(() => (matchSize('md') ? resolveComponent('TransitionCollapseUp') : resolveComponent('TransitionCollapse')))

// workshop panelist
const toolPaneList = computed(() => tools.value.filter(e => e.content))

const toolContentList = ref({
  PaneWorkshopFindDeck: resolveComponent('PaneWorkshopFindDeck'),
  PaneWorkshopDeckLogUpload: resolveComponent('PaneWorkshopDeckLogUpload'),
  PaneWorkshopExport: resolveComponent('PaneWorkshopExport'),
  PaneWorkshopBuildCard: resolveComponent('PaneWorkshopBuildCard'),
  PaneWorkshopCardDetection: resolveComponent('PaneWorkshopCardDetection')
})

// header
const headers = computed(() => [
  {
    link: '/',
    color: token.value && matchSize('md') ? '#000000' : '',
    component: token.value ? resolveComponent('HeaderHome') : resolveComponent('HeaderDefault')
  },
  { link: '/product(/)', color: '#000000', component: resolveComponent('HeaderProduct') },
  { link: '/series(/)', color: nowSeriesColor.value, component: resolveComponent('HeaderSeriesIndex') },
  { link: '/series/(:id)(/)', color: nowSeriesColor.value, component: resolveComponent('HeaderSeries') },
  { link: '/deck(/)', color: '#000000', component: resolveComponent('HeaderDeckIndex') },
  { link: '/deck/(:code)(/)', color: deckMode.value === 'edit' ? '#32c9ff' : nowDeckColor.value, component: resolveComponent('HeaderDeck') },
  { link: '/decklog/(:code)(/)', color: nowDeckColor.value, component: resolveComponent('HeaderDeckLog') },
  { link: '/social(/)', color: '#000000', component: resolveComponent('HeaderSocialIndex') },
  { link: '/social/add(/)', color: '#32c9ff', component: resolveComponent('HeaderSocialAdd') },
  { link: '/social/my(/)', color: '#000000', component: resolveComponent('HeaderSocialMy') },
  { link: '/social/(:code)(/)', color: nowDeckColor.value, component: resolveComponent('HeaderSocial') },
  { link: '/social/(:code)/edit(/)', color: '#32c9ff', component: resolveComponent('HeaderSocialEdit') },
  { link: '/social/topic/(:code)(/)', color: '#32c9ff', component: resolveComponent('HeaderSocialTopic') },
  { link: '/user(/)', color: '#32c9ff', component: resolveComponent('HeaderUser') },
  { link: '/notification(/)', color: '#000000', component: resolveComponent('HeaderNotification') },
  { link: '/daily(/)', color: '#000000', component: resolveComponent('HeaderDaily') },
  { link: '/qa(/)', color: '#000000', component: resolveComponent('HeaderQA') },
  { link: '/policy(/)', color: '#000000', component: resolveComponent('HeaderPolicy') },
  { link: '/eula(/)', color: '#000000', component: resolveComponent('HeaderEula') },
  { link: '/member/(:id)(/)', color: '#32c9ff', component: resolveComponent('HeaderMember') }
])
const defaultHeader = { color: '', component: resolveComponent('HeaderDefault') }
const nowHeader = shallowRef(headers.value.find(e => new UrlPattern(e.link).match(route.path)) || defaultHeader)

watchEffect(() => {
  nowHeader.value = headers.value.find(e => new UrlPattern(e.link).match(route.path)) || defaultHeader
  useHead({
    meta: [{ name: 'theme-color', content: matchSize('md') ? nowHeader.value.color || '#121212' : '#121212' }]
  })
  if (process.client) {
    document.body.style.backgroundColor = matchSize('md') ? nowHeader.value.color || '#121212' : '#121212'
  }
})
</script>

<style lang="sass" scoped>
.root-container
  display: grid
  grid-template-columns: auto 1fr
  grid-template-rows: auto 1fr auto
  height: 100vh
  min-height: 100%
  position: relative
  width: 100vw
  grid-template-areas: "top-bar top-bar top-bar" "nav-bar main-view right-sidebar" "deck-builder deck-builder deck-builder"

.content-container
  grid-area: main-view
  @apply flex flex-col min-h-0 overflow-hidden
.content
  // -webkit-overflow-scrolling: touch
  touch-action: manipulation
  @apply overflow-auto h-screen
</style>
