<template>
  <Transition name="right">
    <slot class="will-change-[opacity,transform,backdrop-filter]" />
  </Transition>
</template>

<style lang="sass">
.right-enter-active, .right-leave-active
  transition: opacity,transform .3s cubic-bezier(0.55, 0, 0.2, 1)
  overflow: hidden
  position: absolute
  width: 100%

.right-enter-from
  transform: translateX(100%)

.right-leave-to
  transform: translateX(-100%)

.right-enter-to, .right-leave-from
  transform: translateX(0)
</style>
