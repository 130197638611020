<template>
  <div class="w-full flex items-center justify-end gap-2">
    <NuxtLink @click="scrollTop" to="/" class="md:hidden flex items-center gap-2 mr-auto">
      <img src="/icon.png" alt="" class="h-10 rounded-lg shadow-lg shadow-zinc-900/70" />
      <LogoTitle class="default-transition fill-white" :class="{
        'h-0': openSearch,
        'h-6': !openSearch
      }" />
    </NuxtLink>

    <ClientOnly>
      <div ref="search"
        class="default-transition grow-1 min-w-0 p-2 rounded-full bg-white text-zinc-700 shadow shadow-zinc-900/50 flex items-center gap-1 overflow-hidden"
        :class="{
          'w-full md:w-fit': openSearch,
          'w-[36px]': !openSearch
        }">
        <MagnifyingGlassIcon class="flex-none size-5 stroke-2 cursor-pointer text-zinc-700"
          @click.stop="openSearch === true ? doSearch(keyword, nowSeries) : openSearch = true" />
        <input
          class="text-sm p-0 bg-transparent border-none focus:ring-0 placeholder:text-zinc-500 placeholder:font-light w-full min-w-0"
          type="text" :placeholder="$t('headerSocial.searchPlaceholder')" v-model="keyword"
          @keyup.enter="doSearch(keyword, nowSeries)" />
        <XMarkIcon class="flex-none size-5 stroke-2 cursor-pointer text-zinc-700" @click.stop="openSearch = false" />
      </div>

    </ClientOnly>
  </div>
</template>

<script setup>
import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/vue/24/outline'
import { onClickOutside } from '@vueuse/core'
import LogoTitle from '~~/assets/svg/logo-zh.svg'

const keyword = ref('')
const openSearch = ref(false)

const search = ref()
onClickOutside(search, () => {
  openSearch.value = false
})

const scrollTop = () => {
  document.querySelector('#main').scrollTo(0, 0)
}

const doSearch = (k, s) => {
  keyword.value = k

  const q = {}
  if (k) q['k'] = k

  setTimeout(async () => {
    await navigateTo({ path: '/social', query: q })
  }, 500)
}

</script>
