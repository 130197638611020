<template>
  <div class="flex flex-col gap-4 my-4">
    <div class="flex items-center gap-2 text-white">
      <FlagIcon class="size-5 stroke-2" />
      <span class="font-bold">輸入您的英雄代碼</span>
    </div>
    <div class="item default-transition">
      <UIInput type="text" placeholder="英雄代碼" v-model="code" @input="status = ''" :disabled="isLoading" />
    </div>
    <small v-if="status === 'error'" class="text-center text-red-500">代碼無效</small>

    <div class="mt-8 flex justify-center gap-2">
      <button class="item md:!w-[20rem] justify-center default-transition" @click="doAddHeroCode(code)">
        <StarIcon class="icon size-6" />
        <span> 成為英雄 </span>
      </button>
    </div>

    <div class="mt-8 flex flex-col gap-2">
      <span class="text-zinc-400 text-sm">
        您可以在
        <a href="https://www.patreon.com/messages?mode=user&tab=direct-messages" class="cursor-pointer text-blue-500 hover:underline" target="_blank">
          Patreon Community
        </a>
        裡的訊息找到您的英雄代碼。<br />
        或是，可以私訊
        <a href="https://facebook.com/BottleNeko" class="cursor-pointer text-blue-500 hover:underline" target="_blank"> Facebook 粉專 </a
        >，我們會協助您完成英雄權限登入。
      </span>

      <span class="text-zinc-400 text-sm">
        關於英雄會員，可以參考 <a @click="goTo('/hero')" class="cursor-pointer text-blue-500 hover:underline">這裡</a>。
      </span>
    </div>
  </div>
</template>

<script setup>
import { FlagIcon, StarIcon } from '@heroicons/vue/24/outline'

const { query } = defineProps({
  query: {
    type: Object,
    default: {}
  }
})

const globalStore = useGlobalStore()
const { updateUserHeroCode } = globalStore
const preferenceStore = usePreferenceStore()
const { showPane } = storeToRefs(preferenceStore)

const router = useRouter()
const { messageSuccess } = useSwal()

const code = ref('')
const status = ref('')
const isLoading = ref(false)

const goTo = path => {
  showPane.value = null
  router.push(path)
}

const doAddHeroCode = async code => {
  isLoading.value = true
  if (!code) return
  const res = await updateUserHeroCode({ code })

  if (res) {
    showPane.value = null
    await messageSuccess('恭喜成功成為英雄會員！')
  } else {
    status.value = 'error'
  }
  isLoading.value = false
}

onMounted(() => {
  setTimeout(async () => {
    if (query.open.toLocaleLowerCase() === 'herocode' && query.code) {
      code.value = query.code
      await doAddHeroCode(query.code)
    }
  }, 1000)
})
</script>

<style lang="sass" scoped>

.topic
  @apply text-lg font-bold mr-auto text-zinc-200
  .subtitle
    @apply block font-normal text-zinc-400 text-xs

.item
  @apply w-full flex items-center gap-2 p-2 rounded-xl ring-2 ring-zinc-800
  &:not(.active)
    @apply bg-zinc-900/50 text-zinc-400 hover:bg-zinc-800 focus-within:bg-zinc-800 active:bg-zinc-800 disabled:text-zinc-800
  &.active
    @apply bg-gradient-to-r from-blue-500 to-cyan-500 shadow-sky-500/50 text-white

  .icon
    @apply flex-none stroke-2
</style>
