<template>
  <TransitionGroup name="move" tag="section" class="grid gap-4 w-full relative grid-cols-[repeat(auto-fit,_minmax(6rem,_1fr))]">
    <template v-for="card in props.cards">
      <div v-if="card.type === 'title'" :key="`card-title-${card.name}`" class="col-span-full flex items-center gap-1">
        <h2 class="font-bold text-lg">{{ card.name }} - {{ card.count }}</h2>
        <div class="ml-auto md:ml-4 bg-black text-white flex items-center gap-1 rounded-full px-2">
          <img src="/soul.gif" class="size-4" />
          <span class="font-mono flex-none"> {{ card.souls }} </span>
        </div>
      </div>

      <ItemCard
        v-else
        :disabled="
          props.isNote ? (props.isStack ? !props.notes.includes(card.id) : props.notes.filter(e => e === card.id).length < card.count) : false
        "
        :card="card"
        :isPrice="isPrice"
        :action="props.isStack ? 'count' : 'disable'"
        :count="props.isNote ? `${props.notes.filter(e => e === card.id).length}/${card.max}` : `${card.max}`"
        :countColor="props.mode === 'add' ? 'green' : props.mode === 'minus' ? 'red' : ''"
        :key="`card-${card.id}-${card.count}`"
        class="relative"
        onlyCover
        :class="{
          hidden: card.count !== 1 && props.isStack
        }"
        @click="emits('select', card)"
      >
        <template #cardRare="{ card }">
          <slot name="cardRare" :card="card" />
          <slot name="editNote" :card="card" />
        </template>
      </ItemCard>
    </template>
  </TransitionGroup>
</template>

<script setup>
const emits = defineEmits(['select'])
const { cardSet } = storeToRefs(useCardStore())
const { matchSize } = useRwd()
const { setting } = storeToRefs(usePreferenceStore())

const props = defineProps({
  isPrice: {
    type: Boolean,
    default: false
  },
  isStack: {
    type: Boolean,
    default: false
  },
  isNote: {
    type: Boolean,
    default: false
  },
  cards: {
    type: Array,
    default: []
  },
  mode: {
    type: String,
    default: ''
  },
  rwd: {
    type: Array,
    default: []
  },
  notes: {
    type: Array,
    default: []
  }
})

const gridClass = computed(() => {
  let c = {}
  let rwdToken = ['', 'xs:', 'sm:', 'md:']
  const rwd = props.rwd.length > 0 ? props.rwd : !matchSize('lg') && setting.value.theaterMode ? [3, 4, 8, 10] : [3, 5, 6, 8]

  rwd.forEach((e, i) => {
    c[`${rwdToken[i]}grid-cols-${e}`] = true
  })
  return c
})

// deal with cardSet
const doSetCardSet = (cards = []) => {
  const cs = cards
    .filter(card => card.type !== 'title')
    .reduce((a, c) => {
      let id = a.map(e => e.id).indexOf(c.id)
      if (id === -1) {
        a.push(c)
      } else {
        a[id].count += c.count
      }
      return a
    }, [])

  // console.log('doSetCardSet', cs)
  cardSet.value = cs
}

watch(
  () => props.cards,
  val => {
    doSetCardSet(JSON.parse(JSON.stringify(val)))
  }
)

onMounted(() => {
  doSetCardSet(JSON.parse(JSON.stringify(props.cards)))
})
</script>

<style lang="sass" scoped>
.init
  @apply grid-cols-1 grid-cols-2 grid-cols-3 grid-cols-4 grid-cols-5 grid-cols-6 grid-cols-7 grid-cols-8 grid-cols-9 grid-cols-10 grid-cols-11 grid-cols-12
  @apply xs:grid-cols-1 xs:grid-cols-2 xs:grid-cols-3 xs:grid-cols-4 xs:grid-cols-5 xs:grid-cols-6 xs:grid-cols-7 xs:grid-cols-8 xs:grid-cols-9 xs:grid-cols-10 xs:grid-cols-11 xs:grid-cols-12
  @apply sm:grid-cols-1 sm:grid-cols-2 sm:grid-cols-3 sm:grid-cols-4 sm:grid-cols-5 sm:grid-cols-6 sm:grid-cols-7 sm:grid-cols-8 sm:grid-cols-9 sm:grid-cols-10 sm:grid-cols-11 sm:grid-cols-12
  @apply md:grid-cols-1 md:grid-cols-2 md:grid-cols-3 md:grid-cols-4 md:grid-cols-5 md:grid-cols-6 md:grid-cols-7 md:grid-cols-8 md:grid-cols-9 md:grid-cols-10 md:grid-cols-11 md:grid-cols-12
  @apply grid-cols-[repeat(auto-fit,_minmax(8rem,_1fr))] grid-cols-[repeat(auto-fit,_minmax(5rem,_1fr))] grid-cols-[repeat(auto-fit,_minmax(6rem,_1fr))] grid-cols-[repeat(auto-fit,_minmax(7rem,_1fr))]
  @apply grid-cols-[repeat(auto-fit,_minmax(8rem,_1fr))] grid-cols-[repeat(auto-fit,_minmax(8rem,_1fr))] grid-cols-[repeat(auto-fit,_minmax(9rem,_1fr))] grid-cols-[repeat(auto-fit,_minmax(10rem,_1fr))]
</style>
